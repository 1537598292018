var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{style:({
  '--theme': _vm.verConfig.theme,
  '--navTheme': _vm.verConfig.navTheme,
  '--bg': _vm.verConfig.pagesBG,
  '--grey': '#b8ecff',
  '--gold': '#deb86b',
  '--black': '#000',
  '--navBg': '#520271',
  '--moduleColor': '#262626',
  '--hallBg': '#000',
}),attrs:{"id":"app"}},[_c('router-view'),_c('WelfareDialog',{attrs:{"content":_vm.welfareDialogContent,"msgId":_vm.messageInfo.id,"next":_vm.nextmsg,"clickOverlay":false,"backgroundImage":require('@tt/assets/home/dialog_bg.png')},on:{"read":_vm.read},model:{value:(_vm.welfareDialogShow),callback:function ($$v) {_vm.welfareDialogShow=$$v},expression:"welfareDialogShow"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }