export default {
  signStr: '1001', // 渠道
  // tab按钮
  tab: {
    my: 'My',
    home: 'Home',
    record: 'Record',
    grab: 'Grab',
    financing: 'Financing',
    make_money: 'Grab an order'
  },
  newTab: {
    home: 'Home',
    money: 'Money',
    shop: 'Shop',
    order: 'Order',
    my: 'My',
    team: 'Team',
    agent: "Agent"
  },
  // 公共的key
  common: {
    unrealized: 'Doing',
    all: 'All',
    distributionTip: '0 delivery 0 distribution fee',
    easeBack: 'Ease back',
    addCarSuccess: 'Join shopping successfully',
    deleteConfirm: 'Are you sure to delete the selected item ?',
  },
  My: {
    download: 'Download APP',
    personal: 'Personal information', // 个人信息
    preOrde: 'Pre-order record', // 预订单记录
    account: 'Account details', // 帐户详细信息
    recharge: 'Recharge record', // 充值记录
    // withdrawal: 'Withdrawal record', // 提款记录
    withdrawal: 'Withdrawal', // （改成提现了）
    invite: 'Invite friends', // 邀请朋友
    announcement: 'Announcement', // 公告
    service: 'Service',
    team: 'Team report', // 团队
    quit: 'Quit', // 退出
    logoutEvent: 'Confirm to exit?', // 确定退出
    language: 'Language setting', // 语言设置
    languageButton: {
      english: 'English', // 英语
      indonesian: 'Indonesian', // 印尼语
    },
    logout: 'logout', // 登出
    // 个人中心的值-小饶
    MemberInformation: {
      SilverMember: 'Silver member', // 银牌会员（这个没用了）
      Invitation: 'Invitation code', // 邀请代码
      balance: 'Account balance', // 账户余额
      Recharge: 'Recharge', // 充值
      Withdraw: 'Withdraw',
      forzenAll: 'Frozen Money'
    },
    // 个人中心-小饶 -Personal information
    PersonalInformation: {
      // 个人中心选项的
      Page: {
        title: 'My information', // 我的信息
        Avatar: 'Avatar', // 头像
        Nickname: 'Nickname', // 昵称
        PhoneNumber: 'Phone number', // 手机号
        BankAccounts: 'Bank accounts', // 银行账户
        Password: 'Password', // 密码
        TransactionPassword: 'Transaction password', // 交易密码
        ReceivingAddress: 'Receiving address', // 收货地址
        levelProgress: "Level progress"
      },
      // 个人中心-修改头像
      portrait: {
        SetAvatar: 'Set avatar', // 设置头像
        button: 'Set now', // 立即设置
      },
      // 个人中心-修改用户名
      Name: {
        ModifyNickname: 'Modify nickname', // title
        title: 'Modify your nickname', // input上方的提示
        name: 'Nickname', // label
        Modify: 'Modify', // 提交按钮
        Please: 'Please enter a nickname', // input的placeholder
      },
      // 个人中心-银行卡信息
      BankAccounts: {
        BankAccounts: 'Binding bank card', // 添加银行卡页面title
        YourInformation: 'Your information', // 姓名上方的title
        RealName: 'Real name', // 真实姓名
        RealNameNote: 'Please set your real name', // 真实姓名的placeholder
        PhoneNo: 'Phone number', // 手机号
        PhoneNoNote: 'Please set Phone number', // 手机号placeholder
        YourBankCard: 'Your bank card information', // 银行卡信息的title
        BankAccount: 'Bank Account', // 银行卡号label
        BankAccountNote: 'Please set Bank Account', // 银行卡号placeholder
        BankName: 'Bank name', // 银行name
        BankNameData: {
          option1: 'Choose account bank',
          option2: 'Bank Central Asia',
          option3: 'Bank Negara Indonesia',
          option4: 'Bank Rakyat Indonesia',
          option5: 'Bank Mandiri',
          // option6: 'Bank Negara Indonesia', // TO DO 他这个是重复的
        }, // 银行选项
        BankBranch: 'Bank Branch', // 银行支行label
        BankBranchNote: 'Please set bank branch', // 银行支行palaceholder
        MoreInformation: 'More information', // whatsApp上方title
        WhatsApp: 'WhatsApp', // whatsApp label
        WhatsAppNote: 'Please set WhatsApp number', // whatsApp palaceholder
        Text:
          'Reminder: Hello, in order to guarantee your rights, please bind the correct card number and bank information. If the account cannot be received due to incorrect filling, this platform does not take any responsibility!', // 添加银行卡的提示
        Settings: 'Settings', // 确定（提示）
      },
      // 个人中心-修改密码
      Password: {
        title: 'Modify login password', // title
        PleaseEnter: 'Please enter your new password', // 输入框上方的
        // LoginPassword:"Login password",
        // LoginPasswordNote:"Please enter the login password",
        phone: 'phone', // 手机
        NewPhone: 'Please enter your cell phone number', // // 验证手机号的轻提示
        verificationCode: 'kode verifikasi', // 验证码
        verificationCodeNote: 'Please enter a verification code', // 请输入验证码
        ConfirmPassword: 'password', // 密码
        ConfirmPasswordNote: 'Please enter a new password', // 输入密码
        Text:
          'Please remember the password, if you forget the password, please contact customer service.', // 提示
        ButtonCode: 'get verification code', // 获取code
        Button: 'OK to modify', // 按钮
        verifyPhone: 'Please enter your cell phone number', // 验证手机号的轻提示
        PhoneNew: 'Please confirm that you have finished', // 是否输入完毕的轻提示
      },
      // 个人中心-修改支付密码
      // 个人中心-修改用户地址
      Site: {
        title: 'Receiving address modification', // 接收地址修改
        ReceivingIdentity: 'Receiving identity information', // 接收身份信息
        ReceivingAddress: 'Receiving address information', // 接收地址信息
        DetailedAddress: 'Detailed address', // 详细地址
        DetailedAddressNote: 'Please enter detailed address', // 请输入详细地址
        Set: 'Set as common address', // 设为公用地址
        Button: 'Confirm', // 完成
        hint: 'Please confirm that you have finished', // 请确认您已完成
      },
      // 个人中心-账户信息
      AccountData: {
        title: 'Transaction details', // 交易明细
        To: 'To', // 到
        search: 'search', // 搜索
        Cancel: 'Cancel', // 取消
        Semua: 'Semua jenis', // 全部
        Penarikan: 'Catatan penarikan', // 吸引力
        IsiUlang: 'Mutasi Top-up', // 重新加载
      },
      // 个人中心-充值记录
      RechargeRecord: {
        title: 'Recharge record', // 充值记录
        Recharge: 'Recharge offline', // 离线充值
        Online: 'Online recharge', // 在线充值
        // Amount: 'Recharge amount Rp:', // 充值金额Rp
        Amount: 'Recharge amount  :', // 充值金额Rs
        Order: 'Order number:', // 订单号
        Status: 'Recharge status:', // 充值状态
      },
      // 个人中心-提款记录
      WithdrawalRecord: {
        title: 'Withdrawal record', // 提款记录
        withdrawal: 'Bank card withdrawal', // 银行卡取款
      },
      // 个人中心-邀请好友
      InviteFriends: {
        Invite: 'Invite link：click copy', // 邀请链接：单击“复制”
        InvitationCode: 'Invitation code:', // 邀请代码
        Click: 'Copy link', // 复制链接
      },
      // 个人中心-系统公告
      Announcement: {
        title: 'Announcement', // 公告
        System: 'System announcement', // 系统公告
      },
      // 个人中心-团队报告
      TheTeamReport: {
        title: 'Team report', // 团队报告
        To: 'To', // 到
        search: 'search', // 搜索
        Cancel: 'Cancel', // 取消
        balance: 'Team amount', // 团队金额 0
        turnover: 'Team recharge', // 团队充值 1
        recharge: 'Team order Commission', // 团队订单佣金 2
        withdrawal: 'Team withdrawal', // 团队提现 3
        commission: 'Team order commission', // 团队佣金 4
        charge: 'Total number of people', // 总人数 5
        people: 'Number of users with recharge', // 有充值用户数 6
        members: 'Number of new users', // 新用户数 7
        NewPeople: 'New user recharge', // 新用户充值人数
        Active: 'Number of new users', // 活跃人数
        Level1: 'Level 1', // 等级1
        Level2: 'Level 2', // 等级2
        Level3: 'Level 3', // 等级3
      },
    },
    // 首页跳转下的四个静态页面 - 小饶
    PlatformIntroduction: {
      PlatformIntroduction: 'Platform introduction',
      // One:
      //   'Indonesia Alliance Mall Co., Ltd. didirikan pada tahun 2012 ，adalah sistem layanan cloud e-niaga yang berfokus pada manajemen proses penjualan untuk membantu perusahaan dengan cepat meningkatkan peningkatan penjualan, rekomendasi petunjuk akurat dipotong, dan mengintegrasikan saluran pemasaran dengan manajemen penjualan dari keseluruhan proses.',
      // Two:
      //   'Platform ini merupakan aliansi yang terdiri dari jutaan pedagang dari Tokopedia, Shopee, Bukalapak, dan Lazada, Prinsip layanannya adalah: pedagang platform meningkatkan volume transaksi, dan anggota platform memperoleh pendapatan komisi dengan mengambil pesanan.',
      Two:
        'This platform is an alliance composed of millions of traders. Its service principle is: the platform traders increase the trading volume, and the platform members earn commission income by receiving orders.',
      // Three:
      //   'Membantu perusahaan mendapatkan pelanggan yang lebih efektif melalui mesin rekomendasi petunjuk pelanggan' +
      //   '"Cloud guest"' +
      //   'adalah platform pesanan pencocokan host kontrol cloud cerdas pihak ketiga.Platform ini bekerja dengan Tokopedia, Shopee, Bukalapak, Lazada dan pedagang platform belanja online lainnya, melalui sistem cloud cerdas untuk mencocokkan pesanan bisnis dengan pengguna platform, sehingga dapat meningkatkan volume perdagangan dan reputasi penjual di platform utama. mode pengiriman pesanan pembelian ditingkatkan, dan pesanan dikirimkan secara otomatis oleh server cloud cerdas jarak jauh.Pengguna platform hanya perlu memberikan alamat penerima yang yang lengkap , nomor telepon dan beberapa informasi belanja secara langsung di platform ， dan masuk ke host cloud cerdas. komputer Robot Cerdas dari jarak jauh akan kontrol cloud secara otomatis menganalisa proses operasi pesanan secara manual, membuat pencocokan pesanan online lebih efisien dan sederhana！',
      Three:
        'The third-party Intelligent Cloud controls the host order matching platform. The platform cooperates with other online shopping platform merchants to match business orders with platform users through intelligent cloud system, so as to increase the transaction volume and seller reputation on the main platform. The delivery method of the purchase order is improved. The order is automatically sent by the remote intelligent cloud server. The platform users only need to provide the complete receiver address, telephone number and some shopping information directly on the platform to enter the smart cloud host.',
      // Four:
      //   'Setiap hari, platform akan menempatkan produk pelanggan yang perlu diekspos ke publik di platform ， dan menyediakan mereka ke platform untuk perampasan pesanan.Untuk menghindari deteksi platform belanja online dan meningkatkan keaslian pesanan , pengguna baru harus meningkatkan informasi pribadi mereka yang sebenarnya dan mengisi alamat penerima yang lengkap sebelum mengambil pesanan, sehingga penjual dapat meningkatkan kedapatan dan penjualan sementara tidak dianggap sebagai pesanan palsu oleh platform belanja online dan dengan demikian mempromosikan perkembangan bisnis yang sehat di platform utama. Untuk membantu penjual secara efektif meningkatkan tingkat keberhasilan pesanan dan menghindari risiko penjual diperiksa untuk pesanan palsu ， platform akan mengoptimalkan pencocokan pesanan untuk hari itu sesuai dengan IP operasi Anda saat ini dan jenis peralatan. Oleh karena itu, pencocokan pesanan perlu diwujudkan melalui algoritma komputasi awan yang cerdas. Mohon tunggu dengan sabar.Pengguna platform secara otomatis akan menjadi agen platform melalui promosi orang baru untuk bergabung dengan platform dan menjadi agen bisa mendapatkan komisi tambahan. ',
      Four:
        'In order to help sellers effectively improve the success rate of orders and avoid the risk of sellers checking false orders, the platform will optimize the order matching of the day according to your current operating IP and equipment type. Therefore, order matching needs to be realized by intelligent cloud computing algorithm. Please wait patiently. The platform users will automatically become the platform agent by promoting new people to join the platform, and they can get extra commission.',
      // Five:
      //   'Anda hanya perlu menggunakan waktu luang Anda untuk online, dan Anda dapat dengan mudah memperoleh pendapatan komisi yang besar setiap hari. Pokok dan komisi akan dikembalikan segera setelah pesanan berhasil diklik dan berhasil dikirim.',
      Five:
        'You just need to use your spare time to surf the Internet, and you can easily earn rich commission every day. After you click and successfully send the order, the principal and commission will be refunded.',
    },
    RuleDescription: {
      RuleDescription: 'Rule description', // title
      One:
        '1.  Anggota harus mengisi alamat penerima dan meningkatkan informasi pribadi sebelum mengambil pesanan. Komisi anggota platform berbeda sesuai dengan tugas yang berbeda .  Jumlah pesanan yang dihasilkan oleh sistem berdasarkan 30% -70% dari saldo rekening anda. ', //
      Two:
        '2.  Karena pesanan barang memerlukan waktu tertentu untuk berlaku, pesanan hanya dapat diterima dengan penjual setelah pesanan berlaku, oleh karena itu penyelesaian komisi platform dan bank tripartit dan anggota menggunakan sistem T + 1. Yaitu: hari ini untuk menarik dan uang akan diterima pada keesokan hari sebelum 23:59. Jika pesanan gagal berlaku setelah waktu yang ditentukan, platform akan membayar di uangmuka, menyelesaikan akun dan mengeluarkan pembayaran dalam waktu yang ditentukan.', //
      Three:
        '3.  Kondisi aula yang sesuai dan rasio komisi    Anggota perak: Semua anggota Silver dapat mengambil 10 pesanan per hari, dan komisi untuk pesanan tersebut adalah 0,30% dari jumlah barang dagangan.', //
      Four:
        'Anggota emas: Untuk membuka anggota emas, Anda perlu mengundang hingga 3 orang untuk masuk ke aula ini. Anda dapat mengambil 35 pesanan per hari, dan komisi untuk pesanan adalah 0,35% dari jumlah barang dagangan.', //
      Five:
        'Anggota platinum: Untuk membuka anggota platinum, Anda perlu mengundang hingga 10 orang untuk memasuki aula ini.Anda bisa mendapatkan 40 pesanan per hari, dan komisi untuk pesanan tersebut adalah 0,40% dari jumlah merchandise.', //
      Six:
        'Anggota platinum: Untuk membuka anggota platinum, Anda perlu mengundang hingga 10 orang untuk memasuki aula ini.Anda bisa mendapatkan 40 pesanan per hari, dan komisi untuk pesanan tersebut adalah 0,40% dari jumlah merchandise.', //
      Seven:
        'Anggota berlian: Untuk membuka anggota Diamond, Anda perlu mengundang 30 orang untuk memasuki aula ini. Anda dapat memperoleh 45 pesanan per hari, dan komisi untuk pesanan tersebut adalah 0,45% dari jumlah barang dagangan.', //
      Eight:
        '4.  Harap periksa nomor kartu deposit platform sebelum mengisi dan platform akan mengubah nomor akun secara tidak teratur. Platform tidak akan bertanggung jawab atas kerugian properti yang disebabkan oleh kesalahan operasional pribadi.', //
      Nine:
        'Catatan: Silakan periksa informasi kartu bank dari platform apakah itu platform ini untuk setiap transfer untuk menghindari kerugian ekonomi Anda.', //
      Ten:
        '5.  Untuk melindungi profit antara penjual dan platform, oleh karena itu setiap ip, nomor kartu bank dan nomor ponsel hanya dapat berlaku untuk satu akun. Semua platform e-niaga utama memiliki mesin kontrol risiko, Jika banyak pengguna di area yang sama dan ip yang sama yang berbelanja di toko yang sama, akan menyebabkan produk bahan peledak toko dihilangkan dan mempengaruhi kredibilitas dan penjualan penjual tidak valid.', //
      Eleven:
        'Jika ditemukan seseorang mengajukan permohonan untuk beberapa akun, pelanggar akan membekukan akun secara permanen dan membekukan saldo selama 30 hari. Saat Anda perlu menarik saldo yang dibekukan (akhir periode berakhir), silakan hubungi layanan pelanggan online platform kami untuk mengaktifkan akun untuk ditarik.', //
      OneData: {
        One: 'Pengenalan tugas:',
        Two:
          'Jenis tugas\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0Tarif komisi',
        Three:
          'Lazada\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa00.30%',
        Four:
          'Bukalapak\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa00.35%',
        Five:
          'Shopee\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa00.40%',
        Six:
          'Tokopedia\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa00.45%',
      },
      TwoData: {
        One: 'Deskripsi tingkat keanggotaan:',
        Two:
          'Jenis keanggotaan\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0Anggota perak',
        Three:
          'Batas penarikan\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa050.000.000',
        Four:
          'Jumlah pesanan\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa030/hari',
        Five:
          'Tingkat komisi\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa00.30%',
        Six:
          'Undang\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0-',
      },
      FourData: {
        One:
          'Jenis keanggotaan\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0Anggota platinum',
        Two:
          'Batas penarikan\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa050.000.000',
        Three: 'Jumlah pesanan\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa040/hari',
        Four:
          'Tingkat komisi\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa00.40%',
        Five:
          'Undang\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa010',
      },
      FiveData: {
        One: 'Jenis keanggotaan\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0Anggota berlian',
        Two:
          'Batas penarikan\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa050.000.000',
        Three:
          'Jumlah pesanan\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa045/hari',
        Four:
          'Tingkat komisi\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa00.45%',
        Five:
          'Undang\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa030',
      },
      Twelve:
        '*Level yang berbeda dapat memperoleh angka ganjil dan rasio komisi. Semakin tinggi level keanggotaan, semakin banyak angka ganjil yang bisa Anda ambil. Semakin besar urutannya, semakin tinggi komisi.', //
    },
    AgentCooperation: {
      AgentCooperation: 'Agent cooperation', // title
      One: 'Peluncuran Standar Tingkat Ketiga untuk Proxy', //问1
      Two:
        'Anggota dapat menjadi proxy platform dengan merekomendasikan anggota baru yang bergabung untuk mendapatkan hadiah tambahan.', //
      Three:
        'Promosikan langsung hadiah pengguna tingkat pertama untuk mendapatkan komisi bonus 9% setiap hari .Untuk hadiah pengguna tingkat kedua adalah komisi bonus 6% setiap hari Untuk hadiah pengguna tingkat ketiga adalah komisi bonus 3% setiap hari.', //
      Four: 'Sebagai contoh:', //
      // Five:
      //   'Proksi tingkat pertama Anda telah mengundang 10 orang untuk bergabung menjadi anggota, proxy tingkat kedua mengundang 20 orang untuk bergabung menjadi anggota dan proxy tingkat ketiga telah mengundang 40 orang untuk bergabung menjadi anggota. Pendapatan per orang untuk sehari adalah 200.000 IDR, jadi bayangkan saja penghasilan harian yang akan Anda dapatkan.', //
      // Six: 'Level pertama: 200.000 x 10 x 9%  = 180.000 IDR', //
      // Seven: 'Tingkat kedua: 200.000 x 20 x 6%  = 240.000 IDR', //
      // Eight: 'Tingkat ketiga: 200.000 x 40 x 3%  = 240.000 IDR', //
      // Nine:
      //   'Level pertama  +  Tingkat kedua  +  Tingkat ketiga  =  660.000 IDR', //
      Five:
        'Proksi tingkat pertama Anda telah mengundang 10 orang untuk bergabung menjadi anggota, proxy tingkat kedua mengundang 20 orang untuk bergabung menjadi anggota dan proxy tingkat ketiga telah mengundang 40 orang untuk bergabung menjadi anggota. Pendapatan per orang untuk sehari adalah 200.000 INR, jadi bayangkan saja penghasilan harian yang akan Anda dapatkan.', //
      Six: 'Level pertama: 200.000 x 10 x 9%  = 180.000 INR', //
      Seven: 'Tingkat kedua: 200.000 x 20 x 6%  = 240.000 INR', //
      Eight: 'Tingkat ketiga: 200.000 x 40 x 3%  = 240.000 INR', //
      Nine:
        'Level pertama  +  Tingkat kedua  +  Tingkat ketiga  =  660.000 INR', //
      Ten:
        'Misalkan Anda mengundang anggota A untuk mendaftar, A mengundang B, dan B mengundang C,', //
      Eleven:
        'Kemudian anggota A adalah pengguna tingkat pertama tim Anda, B adalah pengguna tingkat kedua Anda, dan C adalah pengguna tingkat ketiga Anda.', //
      Twelve:
        'Jumlah hadiah = komisi pesanan A x 9% + komisi pesanan B x 6% + komisi pesanan C x 3%.', //
      Thirteen:
        '*Pengingat: Hadiah komisi untuk semua agen yang merupakan anggota offline dikeluarkan oleh platform ini, dan tidak akan memengaruhi komisi semua agen dan anggota offline!', //
    },
    CommonProblem: {
      CommonProblem: 'Common problem', //title
      One: 'Q:Mengapa bisnis membutuhkan pesanan virtual?', // 问1
      Two:
        'Menurut aturan platform e-commerce utama, semakin banyak orang membeli produk, semakin populer produk tersebut, dan semakin banyak peluang akan didorong dan ditampilkan oleh platform e-commerce. Bisnis e-commerce sedang sengit. Untuk meningkatkan eksposur produk, pedagang perlu meningkatkan volume transaksi komoditas, sehingga mereka memilih untuk menghasilkan pesanan virtual di platform.', //答1
      Three: 'Q:Bagaimana cara menyelesaikan proses pengambilan pesanan?', //问2
      Four:
        'Pilih saluran e-niaga, klik untuk mengambil pesanan secara otomatis, sistem dengan cerdas mencocokkan produk untuk mendapatkan pesanan. Platform menggunakan informasi pribadi Anda yang sebenarnya, alamat pengiriman, dan IP jaringan untuk mengirimkan pesanan ke platform e-commerce untuk mensimulasikan belanja online. Namun, pedagang tidak benar-benar mengirimkan barangnya. Setelah pesanan berhasil dikirim, pokok dan komisi pesanan akan segera dikembalikan ke akun Anda.', //答2
      Five: 'Q:Mengapa saya perlu mengisi ulang sebelum menerima pesanan?', //问3
      Six:
        'Untuk memastikan keaslian pesanan, setiap platform pemesanan yang disimulasikan memerlukan pembayaran nyata ke situs web e-niaga. Untuk melindungi kepentingan platform dan pedagang, saldo akun Anda akan dikurangi saat pesanan dikirim, dan akan segera dikembalikan setelah pesanan selesai.', // 答3
      Seven:
        'Q:Bagaimana cara meningkatkan jumlah pesanan ambil dan meningkatkan komisi?', //问4
      Eight:
        'Dengan mengundang teman untuk mendaftar, semakin tinggi levelnya, semakin banyak jumlah total pesanan per hari, dan semakin tinggi rasio komisi;Sistem secara otomatis mencocokkan jumlah pesanan antara 30% dan 70% dari saldo akun, oleh karena itu, semakin besar saldo akun, semakin tinggi jumlah setiap pesanan dan semakin tinggi komisi.', // 答4
      Nine: 'Q:Bagaimana cara mengundang teman untuk mendaftar?', //问5
      Ten:
        'Klik "Undang Teman", salin tautan berbagi atau kode QR, dan kirimkan ke teman, teman sekelas, kolega, dan keluarga Anda melalui perangkat lunak sosial. Anda harus mengisi kode undangan untuk pendaftaran di platform ini, dan pihak lain akan menjadi anggota tim Anda setelah pendaftaran.', // 答5
      Eleven: 'Q:Bagaimana cara menarik uang tunai?', //问6
      Twelve:
        'Anda dapat mengajukan penarikan tunai setelah memenuhi kuantitas minimum order. Jika syarat kuantitas minimum order tidak terpenuhi, lanjutkan saja hingga persyaratan terpenuhi. Setelah penarikan disetujui, platform akan mendepositkan dana ke rekening bank terikat Anda, dan waktu penerimaan yang tepat tergantung pada bank Anda.', // 答6
    },
    // 首页会员升级页面 - 小饶
    MemberUpgrade: {
      MemberUpgrade: 'Member upgrade', // title
      Member: 'Member level：', //会员等级
      Orders: 'Orders available every day: ', // 可用订单
      Commission: 'Commission increase', // 佣金奖金
      DedicatedOne: 'Dedicated customer service', // 特殊客服服务
      DedicatedTwo: 'Dedicated customer service', // 特殊客服服务
      Swipe: 'Swipe left and right to see more', // 说明
      One: {
        Silver: 'Silver member', // 银卡会员
        Need: 'Need to invite 0 people', // 邀请
        Withdrawal: 'Withdrawal limit:50.000.000', // 提款限额
        quantity: 'Order quantity:10/day', // 订单数量
        rate: 'Commission rate:0.30%', // 佣金率
        fee: 'Withdrawal fee:2.00%', // 提现费
        Activated: 'Activated', //已解锁
      },
      Two: {
        Silver: 'Gold member', // 黄金会员
        Need: 'Need to invite 3 people', // 邀请
        People: '3 people still short', // 缺人
        Withdrawal: 'Withdrawal limit:50.000.000', // 提款限额
        quantity: 'Order quantity:35/day', // 订单数量
        rate: 'Commission rate:0.35%', // 佣金率
        fee: 'Withdrawal fee:2.00%', // 提现费
        Activated: 'Daily recharge amount:1.000.000', //每日提款补充
        Invite: 'Invite now', // 立即邀请
      },
      Three: {
        Silver: 'Platinum member', // 白金会员
        Need: 'Need to invite 10 people', // 邀请
        People: '10 people still short', // 缺人
        Withdrawal: 'Withdrawal limit:50.000.000', // 提款限额
        quantity: 'Order quantity:40/day', // 订单数量
        rate: 'Commission rate:0.40%', // 佣金率
        fee: 'Withdrawal fee:2.00%', // 提现费
        Activated: 'Daily recharge amount:1.500.000', //每日提款补充
        Invite: 'Invite now', // 立即邀请
      },
      Four: {
        Silver: 'Diamond member', // 钻石会员
        Need: 'Need to invite 30 people', // 邀请
        People: '30 people still short', // 缺人
        Withdrawal: 'Withdrawal limit:50.000.000', // 提款限额
        quantity: 'Order quantity:45/day', // 订单数量
        rate: 'Commission rate:0.45%', // 佣金率
        fee: 'Withdrawal fee:2.00%', // 提现费
        Activated: 'Daily recharge amount:2.000.000', //每日提款补充
        Invite: 'Invite now', // 立即邀请
      },
    },
    PhoneNew: 'Please confirm that you have finished', // 是否输入完毕的轻提示
    // 轻提示 - 小饶
    Toast: {
      One: 'Save successfully', //保存成功
      Two: 'Not Succeed', //保存失败
    },
    // 确定和取消的多文本 -- 小饶
    Button: {
      One: 'Cancel', //取消
      Two: 'Confirm', //确定
    },
    logout: 'logout', // 个人中心下的退出按钮-小饶
  },
  Record: {},
  Grab: {},
  Financing: {
    time: 'Time', // 时间
    day: 'Day', // 天
    Fina: 'Financial', // 标题
    // assets: 'Total assets Rp', // 总资产RP
    assets: 'Total assets  ', // 总资产Rs
    // amount: 'Financial amount Rp', // 财务金额RP
    amount: 'Financial amount  ', // 财务金额Rs
    revenue: 'Total revenue', // 总收入
    earnings: "Yesterday's earnings", // 昨日总收入
    Balance: 'Balance transfer', // 余额转入
    // RP: 'Rp', // 钱的单位(印尼)
    RP: ' ', // 钱的单位(印度)
    Please: 'Please enter tran', // 金额输入框placeholder
    Estimated: 'Estimated income', // 预计收入文字
    Income: 'Income Standard', // 收入标准文字
    TransferOut: 'Transfer out', // 转出btn文字
    ransferIn: 'Transfer in', // 转入btn文字
    SukuBung: 'Suku bunga', // 商品名称
    Transfer: 'Transfer in failed', //转入提示
    Pleaseint: 'Please enter the amount',//提示
    Successful: 'Successful transfer',
    Please:
      'Please input the transfer in amount Silakan masukkan transfer dalam jumlah', // 请输入转入金额
    RansferInSU: {
      FinancialAcc: 'Financial Access Record',
      Acc: 'Access log',
    },
    // 首页客服页面
    ServiceSu: {
      Please: 'Please enter...', //请输入
      send: 'send out', //发送
      Online: 'Online Service', //在线客服
      Service: 'Service', // title
      Customer: 'Pelayanan pelanggan', //客服服务
      Ifyou:
        'If you encounter problems and need help, please contact online cunstomer servic', // 服务说明
      // Layanana8: 'Layanana Pelanggan No.8 (WhatsApp)', //服务号
      Layanana8: 'Customer service center No.8 (WhatsApp)', //服务号
      // Layanana9: 'Layanana Pelanggan No.9 (WhatsApp)', // 服务号
      Layanana9: 'Customer service center No.9 (WhatsApp)', // 服务号
      Dedicated: 'Dedicated cunstomer service', //专属客服
      Telegram8: 'Layanana Pelanggan No.8 (Telegram)', //服务号
      Telegram008: 'Telegram008', //服务号
      Telegram9: 'Layanana Pelanggan No.9 (Telegram)', //服务号
      Telegram009: 'Telegram009', //服务号
    },
  },
  Home: {
    HallofFame: 'Hall of Fame',
    Member: 'Member Nwes', // 新闻
    Mission: 'Mission Hall', // 宣讲堂
    Learnde: 'Learnde', // 信息
    Hello: 'Hello', // 你好
    Let: 'Let Victory Mall open the way to wealth', //说明
    total: 'My total assets', //我的总资产
    Withdraw: 'Withdraw', //收回
    Recharge: 'Recharge', //充值
    // RP: 'Rp', //Rp
    RP: ' ', //ZAR
    // earningsRp: "Yesterday's earningsRp", //昨天的收益
    // incomeRp: 'Cumulative incomeRp', //累计收益
    // rarningsRp: "Today's earningsRp", //今日收益
    earningsRp: "Yesterday's earningsRs", //昨天的收益
    incomeRp: 'Cumulative incomeRs', //累计收益
    rarningsRp: "Today's earningsRs", //今日收益
    Service: 'Service', // 服务
    Invite: 'Invite', // 邀请
    Lazada: 'Lazada', // 会员白银
    Bukalapak: 'Bukalapak', // 会员黄金
    Shopee: 'Shopee', // 会员白金
    Tokopedia: 'Tokopedia', //会员钻石
    silver: 'Channel for silver members', //白银说明
    gold: 'Channel for gold members', //黄金说明
    platinum: 'channel for platinum members', // 白金说明
    diamond: 'channel for diamond members', //钻石说明
    Agent: 'Agent order Commission', // 滚动信息
    Order: 'Order Commission', //滚动信息
    // 首页充值页面
    RechargeSu: {
      Recharge: 'Recharge', //充值
      Catatan: 'Catatan khusus:', //充值说明
      Next: 'Next', //下一步
      RechargeAm: 'Recharge amount', //充值金额
      Enter: 'Enter the recharge amount', //输入充值金额
      Quick: 'Quick selection', // 快速选着
      nameP: 'Please enter your real name', //请输入姓名
      name: 'Name', //姓名
      Pleaseamount: 'Please enter the amount', //请输入金额
      Pleaseint: 'Please enter the amount ', //只能输入0到9之间的整数：
      Pleasename: 'Please enter your name', //请输入姓名
    },
    // 首页子页面-Withdraw
    WithdrawSu: {
      Withdraw: 'Withdraw', // title
      Withdrawal: 'Withdrawal amount', // 提现金额
      WithdrawalRe: 'Withdrawal rate', // 提现率
      Input: 'Input withdrawal amount', // 输入提款金额
      Balance: 'Balance', // 金额
      WithdrawAll: 'Withdraw all', //  全部选中
      Mobile: 'Mobile phone numbe', // 手机号码
      Bank: 'Bank card number', // 银行卡号
      Owned: 'Owned bank', // 所属银行
      Account: 'Account name', // 用户名
      Transaction: 'Transaction password', // 密码
      Withdrawim: 'Withdraw immediately', // 提现按钮
      Pleaseamount: 'Please enter the amount', //请输入金额
      Pleasephone: 'Please enter your phone number', //请输入您的手机号
      Pleasecard: 'Please enter your bank card number', //请输入银行卡号
      Pleasebank: 'Please enter your bank name', //请输入您的银行
      Pleasename: 'Please enter your name', //请输入姓名
      Pleasepassword: 'Please enter transaction password', //请输入交易密码
      Pleaseint: 'Please enter the amount', //只能输入0到9之间的整数：
      PasswordError: 'Wrong password', // 密码错误
      Successful: 'Already applied for withdrawal', //已经申请提款
      Insufficient: 'Insufficient withdrawal balance', //提现余额不足
      Unbound: 'The bank card has not been bound', //银行卡还没有绑定
      Binding: 'Bound your bank card', //绑定银行卡
      idCard: 'ID card No', // 身份证
    },
  },
  Login: {
    indexTitle: 'Login in',
    remenber: 'Remember the password',
    versions: 'V.86', // 版本号
    masuk: 'Sign in', // 标签
    name: 'App download', // 标签旁边的名称
    forgot: 'Forgot', // 找回密码文字
    registrasi: 'Registration', // 注册账号文字
    loginButton: 'Sign in', // 登录的btn
    nameInput: 'Enter your phone number', // 登录时账号input提示
    pwdInput: 'Enter your password', // 登录时密码input提示
    notLoginHint: 'Sign in', // 返回登录弹窗提示
    goLoginText: 'confirm', // 返回登录弹窗去登陆btn
    Forgot: {
      title: 'Retrieve password',
      registerTitle: 'Register',
      phone: 'Enter your phone number', // 账号提示
      code: 'Enter verification code', // 验证码提示
      area: 'Please enter area code', // 暂时未使用
      sendCode: 'send code', // 发送短信btn文字
      password: 'Password', // 第一次密码提示
      againPassword: 'Confirm password', // 再次输入密码提示
      confirm: 'Submit', // 提交
      goBack: 'Had an account,Sign in now', // 返回
      register: 'Daftar', // 暂时未使用
    },
    Daftar: {
      title: 'Register',
      area: 'Please enter area code',
      phone: 'Enter Mobile phone number',
      code: 'Enter verification code',
      userName: 'nickname',
      password: 'Password',
      againPassword: 'Confirm password',
      referrer: 'Invitation code(ID)', // 推荐人号码
    },
  },
  Cart: {
    title: 'Order shop', // 订单商城
    explain: 'Description', // 说明
    // info: {
    //   // allMoney: 'My total\n Aset Rp', // 我的总资产Rp
    //   allMoney: 'My total\n Aset  ', // 我的总资产Rs
    //   dayAllOrder: `Today's\n total order`, //今天的总订单
    //   dayTrustMoney: `Today's\n commision`, // 今天委托金
    //   yesterdayMoney: `Yesterday's\n income`, // 昨天的收入
    // },
    text: {
      residue: 'Remaining', // 剩余
      one: 'Order', // 单
      rebate: 'Return commission', // 返佣
      // buyBtn: 'Grab an order now', // 立即抢单
      buyBtn: 'Buy now', // 立即抢单（换文字）
      toGet: 'Akan mendapat', // 即将获得
      orderSuccess: 'Order successful', // 抢单成功
      orderGetMoney: 'Payment successful', // 付款成功
      orderWaiting: 'Waiting', // 等待中
      orderOver: 'Done', // 完成
    },
    state: {
      all: 'All', // 全部
      going: 'On proccess', // 进行中
      paid: 'Paid', // 已支付
      frozen: 'Frozen', // 已冻结
      over: 'Done', //已完成
    },
    zzInfo: {
      buyIng: 'Purchasing', // 购买中
      // buySucceedFindDT:
      //   'Click the status view after the purchase\n is successful', // 购买成功点击动态查看即可
      ok: 'Okay', // 好的
      buyFailed: 'Purchase failed', // 购买失败
      notLevel: 'Your VIP level is insufficient', // 您的会员等级不足
      buyJurisdiction1: 'If want purchase a gold membership orde', // 如需购买黄金会员订单
      buyJurisdiction2: 'If want purchase a platinum membership order', // 如需购买白金会员订单
      buyJurisdiction3: 'If want purchase a diamond membership order', // 如需购买钻石会员订单
      meet: 'One of the following conditions must be fulfilled', // 需满足以下条件之一
      // invite: '1. Invite 10 friends to make money together', // 1.邀请10个好友一起赚钱
      invite1: 'Invite 3 friends to make money together', // 1.邀请3个好友一起赚钱
      invite4: 'Invite 10 friends to make money together', // 1.邀请10个好友一起赚钱
      invite3: 'Invite 30 friends to make money together', // 1.邀请30个好友一起赚钱
      // buySendMoney: '2. Spend Rp2000 to buy membership diamond', // 2.花费Rp2000购买砖石会员
      buySendMoney: '2. Spend Rs2000 to buy membership diamond', // 2.花费Rs2000购买砖石会员
      invite2: 'Invite friends', // 邀请好友
      buy: 'Buy now', // 立即购买
      myMoney: 'Your account balance', // 您的账户余额
      under: 'Below', // 低于
      notSendMoney: 'Cant do transaction', // 不能进行交易
      goTopUp: 'Please go top-up', // 请前往充值
      know: 'I know', // 我知道了
      account: 'Settlement', // 结算
    },
    hint: {
      p1: '1. Different membership levels have different purchase orders',
      p2:
        '2. The higher your membership level, the higher the order commission amount that can be purchased',
      p3: '3. For purchased orders, you can monitor the order status any time',
    },
  },
  Hint: {
    SendCodeSuccess: 'The verification code is sent successfully!',
    closed: 'Closed', // 停服
    notTime: 'No time selected', // 没有选择时间
    notTimeFailed: 'Time selection failed', // 选择时间错误
    success: 'Successful', // 成功
    failed: 'Failed', // 失败
    phoneNot: 'No hp/No akun tidak bisa kosong', // 手机号/账号不能为空
    pwdInconformity: 'Password not same', // 密码不一致
    notMoney: 'Money insufficent', // 货币不足
    notEvent: 'Feature cant use yet', // 功能暂未开启
    notInput: 'Incomplete data input', // 输入内容不完整
    loading: 'Loading', // 加载中
    notMore: 'No more', // 没有更多了
    notPhone: `Account doesn't exist`, // 账号不存在
    yesPhone: 'Account already exists', // 账号已存在
    levelNot: 'LevelNot', // 等级不够
    serverMaintenance: 'Please be patient,The game is under maintenance', // 服务器维护中
    invite: 'Invite friends\n to make money\n together', // 分享页面的文字
    goodsNumNo: 'Insufficient quantity', // 物品数量不足
    haveBuy: 'Bought', // 订单页面已买文字
    depositBtnText1: 'Diterima', // 已领取
    depositBtnText2: 'Available', // 可领取
    depositBtnText3: 'Receive', // 领取
    // commissionMoney: 'Return commission Rp:', // 返佣额度
    commissionMoney: 'Return commission  :', // 返佣额度
    bill: 'bill', // 玩家查询充值流水记录的记录
    billRecordTitle: 'Billing records', // 流水记录
    balance: 'balance', // 余额
    reference: 'Paste invitation code', // 粘贴邀请码
    updateText: 'Please update to the latest version',
  }, // 提示
  newAdd: {
    all: 'all',
    Completed: 'completed',
    unfinished: 'unfinished',
    GetCodeBtn: 'get verification code',
    copySuccess: 'Copy success!',// 复制成功
    copyError: 'Copy the failure!',// 复制失败
    noLoginUserName: 'Login Please',// 请登录
    Withdraw: 'Withdraw',// 提现
    Recharge: 'Recharge',// 充值
    commission: 'commission',// 佣金
    single: 'single',// 单
    missionHall: 'Mission hall',// 任务大厅
    videoTutorial: 'Video tutorial',// 视频教程
    helpCenter: 'Help center',// 帮助中心
    inviteFriends: 'Invite friends',// 邀请好友
    play: 'Play now',
    help1Title: 'How to earn commissions with Mudo',// 如何通过CG赚取佣金
    help1Context: 'E-commerce business owners need to increase the sales of the store, so we only need to complete the requirements of E-commerce store and complete the order on the Mudo software. The owner will give us a commission. Each user can receive 10 order tasks per day. You can earn 10% to 30% commission in one day.',
    // 电商老板需要添加店铺的销售额,所以我们只需要在CG上完成电商店铺的要求,完成订单即可。业主会给我们佣金。每个用户每天可以接收10个订单任务。您可以在一天内赚取 10% 到 30% 的佣金。
    help2Title: 'How to increase the number of mission',
    help2Context: 'Because there are too many part-time employees, the company stipulates that all online part-time employees use the only whatsapp to get in touch and receive mission.',
    help3Title: 'How to complete the mission',
    help3Context: 'it is to help major merchants to increase sales, and to reward you according to the number of transactions. For every successful order, you earn commissions and sellers earn reputation.',
    referralLink: 'Referral link:',// 推荐链接
    copy: 'Copy',// 复制
    blance: 'Blance',// 余额
    todayOrder: "Today's Order",// 今日订单
    provided: 'The data is provided by',// 本数据由提供
    Transaction: 'Transaction',// 交易
    Frozen: 'Frozen', // 冻结
    Done: 'Done',// 完成
    Cancel: 'Cancel',// 取消
    ExpectedReturn: 'Expected return:',// 预计返还:
    ImmediatePayment: 'Immediate payment', // 立即支付
    AvailableNum: 'There are still',// 可用次数
    OrderImmediately: 'Order immediately', // 马上配单
    MakeMoneyEveryDay: 'Make money every day', // 日日赚钱
    TodayRecord: "Today's record", // 今日记录
    AllocatCommissions: 'Allocation of commissions', // 分配佣金
    FrozenAmount: 'Frozen amount', // 冻结金额
    TaskMatch: 'Task order randomness',// 任务分配
    AvailableBalance: 'Available Balance',// 可用余额
    WaitDispatch: 'Waiting for dispatch,Please contact the store manager!',// 等待分配任务,请联系店长!
    OrderDescription: 'Order description', // 今日记录
    // CouldMatch: 'Each account can match',// 每个账户每天可配
    CouldMatch: 'Tasks are randomly matched by the system',
    OrderPerDay: 'single.',// 单。
    DescriptionRate: 'Order placement commission is uniformly',// 配单佣金统一为订单金额的
    DescriptionPeriod: 'of the order amount.',// 。
    Description3: 'The system is based on LBS technology and automatically matches products through the cloud.',
    // 系统基于LBS技术, 通过云端自动匹配商品。
    Description4: 'In order to prevent the supervision of the platform, if the order \
    is not confirmed and submitted within 20 minutes, the system will \
    freeze the order amount .',
    // 为防止平台监控,如果订单为在20分钟内确认并发送,系统将冻结订单数量。
    Note: 'Note:', // 注意事项:
    DescriptionNote: "When the platform matches the order to the user, the platform\
    submits the information of the order to the merchant backend. If the\
    user does not submit the order within 20 minutes, the amount of the\
    order will be frozen by the system . In order to circumvent the\
    supervision system, user accounts will be scored, which will\
    directly affect the time and reputation of the user's next order\
    matching! If there are other things that need to be dealt with,\
    please click to stop the order in time to avoid freezing due to\
    timeout",
    // 当平台为用户匹配订单时, 平台将订单信息提交给商家后台。如果用户在20分钟内未提交订单，
    // 订单金额将被系统冻结。为避免监管系统，会对用户账号进行评分，直接影响用户下一次撮合的
    // 时间和口碑！如有其他需要处理的，请及时点击停止下单，以免超时冻结
    OrderNum: 'Order number:',// 订单号:
    TotalOrderPrice: 'Total order price', //总价:
    SubmitNow: 'Submit now',// 立即提交
    Record: 'Record', // 记录
    MemberAccount: 'Member account', // 会员账户
    AccountPlaceholder: 'Please enter your member account',// 请输入您的账户
    RechargeAmount: 'Recharge amount',// 充值金额
    RechargeAmountPlaceholder: 'Please enter the amount of recharge', //请输入充值金额
    SelectRechargeMethod: 'Select the recharge method', // 选择充值方式
    NotBeenSentOrder: 'The order has not been sent, please contact the staff to send the order!', // 尚未派发订单,请联系工作人员派发订单!
    // TodayOrderOver: "Today's order has been completed, please try again tomorrow!",
    TodayOrderOver: 'Contact online customer service',
    // 今日订单已完成,请明天再试!
    NotLogin: 'You are not logged in, Please log in and try again!',
    // 您尚未登录,请登录后重试!
    HaveNoteMethod: 'There is no such recharge method, please choose another method~',
    // 暂时没有这样的充值方法,请选择其他充值方法~
    Login: 'Login', // 登录
    AccountRecord: 'Income and expenditure',// 账变记录
    RealName: 'Actual Name', // 真实姓名
    IFSCCODE: 'IFSC CODE', // IFSC
    BankName: 'Bank name', // 银行卡名称
    ChainType: 'Chain type', // 钱包类型名称
    BankNum: 'Bank number (AC ID)', // 银行卡号
    Mobile: 'Mobile', // 手机
    MobileNum: 'Mobile Number', // 手机号码
    Email: 'Email', // 邮箱
    EmailRequired: 'Email is required', // 邮箱必填
    AddBankCardNote: 'Note: Bank card is important information, please fill it out carefully!',
    AddWalletNote: 'Note: Wallet address is important information, please fill it out carefully!',
    // 银行卡是重要信息, 请仔细填写!
    Submit: 'Submit', // 提交
    AddBankCardTitle: 'add-bank-card',// 添加银行卡
    AddWalletTitle: 'Add wallet address',// 添加钱包
    BankCard: 'Bank card', // 银行卡
    WalletInfo: 'Wallet info',
    Name: 'name', // 姓名
    CardNum: 'Card number (AC ID)', // 卡号
    WalletAddress: 'Address', // 卡号
    Reading: 'is being reading...', // 正在读取中...
    AllBalance: 'overall balance', // 总余额
    EnterWithdrawalAmount: 'Please enter the withdrawal amount',// 请输入体现金额
    AllOut: 'All out',// 全部
    // AskNow: 'Ask now', // 提现
    AskNow: "Submit",
    EnterPwdPlaceholder: 'Please input a password',// 请输入密码
    NotHaveCard: 'You have not set up your bank card, do you want to go to the setting?',
    NotHaveWallet: 'You have not set up your wallet address, do you want to go to the setting?',
    // 你尚未绑定银行卡,您要去绑定银行卡吗?
    GetBankInfoFail: 'Failed to retrieve bank information', // 拉取银行信息失败
    EnterValidPayPwd: 'Please enter a valid payment password!', // 请输入有效的支付密码
    Balance: 'Balance', // 余额
    Total: 'Total', // 总共
    total: 'total', // 总共
    records: 'records', // 记录
    DisplayPerPage: 'each page displays', // 每页显示,
    NullNum: '',// 条
    pages: 'pages.', // 页
    toAudit: 'to audit', // 待审核
    succeed: 'succeed', // 成功
    RechargeRecordTitle: 'recharge-record',// 充值记录
    rests: 'rests', // 其他
    Arrive: 'Arrive', // 到账
    Income: 'Income',// 收入
    Expenditure: 'Expenditure',// 支出
    forzenCommission: 'unclaimed commission',// 冻结佣金     以前的Frozen Commission
    companyIntro: 'Company profile', // 公司简介
    companyTitle: 'Tata Mall is part of Tata Group',
    warmTitle: 'warm prompt',
    companyText: "Tata Group is India's largest business group, founded in 1868 and headquartered in Mumbai, India. Business operations involve seven business areas: communications and information technology, engineering, materials, services, energy, consumer products and chemical products. As a rapidly growing business group in India, the Tata Group has more than 200 subsidiaries, with operations in more than 80 countries on 6 continents, and exports of products and services to 85 countries. Tata Group's total revenue for the 2020-2021 fiscal year is US$100.09 billion, of which 58% comes from overseas operations. The number of employees of the Group around the world is approximately 450,000. Since 153 years, the name Tata in India has been widely respected for its adherence to good values and business ethics. Ranked as the 11th most respected company in the world by Forbes.",
    depositAddress: 'Deposit address',
    rechargeSteps: 'Recharge steps',
    completePayment: 'Complete payment',
    rechargeNow: 'Recharge now',
    rechargeCancel: 'Cancel payment',
    bankName: 'Bank name',
    payee: 'The payee',
    cardNum: 'Bank card number',
    reviewing: 'Pending',
    remittance: 'Success',
    notThrough: 'Failed',
    OrderID: 'OrderID',
    Amount: 'amount',
    toCutScreen: "Please take a screenshot if the image is not available!",
    cardTabBank: 'Bank',
    cardTabUSDT: 'USDT',
    name: 'Name',
    chainType: 'Chain type'
  },
  // 我的
  mine: {
    servier: 'Service'
  },
  add: {
    Commission: 'Commission',
    Yesterday: 'Yesterday',
    Cumulative: 'Cumulative',
    Today: 'Today',
    TeamNum: ' Total team size',
    TeamMoney: ' Total Team Commission',
    Personalcenteraccountchangerecord: 'Account fund flow records',
    lockedbalance: 'locked balance',
    Cumulativeinvitation: 'Cumulative invitation', //
    Validinvitation: 'Valid invitation',
    Accumulatedrebate: 'Accumulated rebate',
  },
  "Merchant": 'Merchant',
  "all": "All",
  "btn.recharge": "Recharge",
  "btn.withdraw": "Withdraw",
  "btn.service": "Service",
  "merchant.all": "All",
  "merchant.long": "Long Time",
  "merchant.short": "Short Time",
  "merchant.hot": "Pop merchants",
  "merchant.list": "Merchants list",
  "tab.merchants": "Merchants",
  "orderDeadlineText": "order deadline",
  "orderResultText": "matching result",
  // "status.waiting": 'To be drawn',
  "status.waiting": 'Pending',
  "status.done": "Has been drawn",
  "status.fail": 'lottery error',
  "status.open": 'Opening...',
  "open.big": 'Big',
  "open.small": 'small',
  "open.single": 'single',
  "open.double": 'double',
  "大小单双": "Big small single double",
  "大单": "Big single",
  "大双": "Big double",
  "小单": "small single",
  "小双": "small double",
  "btn.clear": "clear",
  "btn.match": "Match",
  "hint.moneyLess": "Insufficient balance!",
  'hint.opening': "Orders are not allowed during closing!",
  "hint.matchSuccess": "match successfully!",
  "hint.matchFail": "match failed!",
  "popup.title.buyConfirm": "Confirm order",
  'popup.text.data': 'data',
  'yuan': 'yuan',
  'popup.title.matchAmount': 'match amount',
  'popup.title.matchContent': 'match content',
  "hint.confirmBuy": "Please complete the order confirmation within the deadline to avoid missing the order!",
  "popup.btn.match": "Match",
  'hint.toAddOrder': 'Please select order number!',
  'hint.toPerPrice': "Please enter the order amount!",
  "popup.title.matchResult": "match result",
  'popup.orderId': "order number",
  "tab.activity": "Activity",
  "toDetails": "check the details",
  "activity.title": "Activity Area",
  "activity.time": "activity time",
  "nav.activityDetails": "Activity details",
  "order.status.all": "All",
  // "order.status.waiting": 'To be drawn',
  "order.status.waiting": "Pending",
  "order.status.done": "Done",
  "order.typeTitle": "type",
  "order.totalPrice": "bet amount",
  "order.winPrice": "income",
  "order.status": "status",
  "nav.orderDetails": "Order details",
  "orderDetails.title.time": "betting time",
  "orderDetails.title.type": "merchant",
  "orderDetails.title.expect": "Issue",
  "orderDetails.title.method": "method",
  "orderDetails.title.result": "result",
  "orderDetails.title.inType": "purchased",
  "orderDetails.title.odds": "odds",
  "orderDetails.title.inPerPrice": "purchase price",
  "orderDetails.title.inNum": "Purchase quantity",
  "orderDetails.title.winAmount": "income",
  "game.perPrice.text": "per item",
  "balance.text": "Balance",
  "input.permoney.placeholder": "enter amount",
  "user.tools.introduce": "introduce",
  "user.tools.crypto": "crypto",
  "user.tools.ba": "Binance",
  "page.walletList": "Wallet List",
  "game.rule.title": "Instruction",
  addWallet: "Add",
  "walletTypeBank": "Bank",
  "walletTypeUSDT": "USDT",
  "btn.editWallet": "edit",
  agent: {
    title: {
      memberControl: "Member management",
      share: "Share Referral Link",
      agentRead: "Agency Policy",
      betInfo: "Betting Information",
      paymentInfo: "Payment Information",
      teamInfo: "Team report",
      daily: "Daily",
      agent: "Agent",
      agentCommission: "Agency commission"
    }
  },
  'My.financial': "Financial",
  "page.financial": "Financial",
  "page.financialRecord": "Financial Record",
  "hint.InternetErr": "Network error, please try again later!",
  "totalDeposit": "Total deposit",
  "totalRevenue": "Total revenue",
  "btn.transferInto": "Transfer into",
  "btn.transferOut": "Transfer out",
  "page.transferInto": "Transfer Into",
  "page.transferOut": "Transfer Out",
  "balanceTransfer": "Balance Transfer",
  "availableBalance": "Available Balance",
  "depositRate": "Deposit rate",
  "days": "days",
  "hint.success": "Success",
  "hint.fail": "Fail",
  "status.into": "Transfer Into",
  "status.out": "Transfer Out",
  "label.amount": "Amount",
  "label.time": "Time",
  "my.vip": "VIP privileges",
  "my.verifyPhone": "Verify phone",
  "my.modifyPass": "Change the password",
  "my.wallet": "Wallet",
  "my.betRecord": "Betting record",
  page: {
    member: "member",
    teamReport: "Team Report",
    agentReport: "Agent Report",
    agentCommission: "Agent commission",
    verifyPhone: "Phone verification",
    withdrawRecord: "Withdraw record",
    games: "Games",
    openRecord: "Open Record"
  },
  placeholder: {
    member: "search for members"
  },
  btn: {
    search: "search",
    sort: {
      level: "level",
      indate: "indate"
    },
    confirm: "Confirm",
    cancel: "Cancle"
  },
  member: {
    title: {
      level: "level"
    },
    label: {
      indate: "indate",
      loginData: "Log in time",
      superior: "superior",
      member: "member"
    },
    notLogin: "not logged in",
    toDetails: "View details",
  },
  memberDetails: {
    tabs: {
      teamInfo: "Team Statistics",
      lower: 'lower level'
    },
    lowerMember: "junior member",
    teamBalance: "Team balance",
    ownBalance: "Personal balance",
    personalRecord: "Personal Records",
    teamRecord: "Team Record",
    btn: {
      today: "Today",
      yesterday: "Yesterday",
      thisMonth: "This month",
      lastMonth: "Last month"
    },
    table: {
      personalBalance: "Balance of {Username}",
      childrenNum: "Total number of members of {Username}",
      rechargeAmount: "Amount {Username} deposited",
      withdrawAmount: "Withdrawal amount {Username}",
      betAmount: "Amount {Username} bet",
      winAmount: "Amount {Username} won",
      groupBalance: "Group balance",
      teamProfit: "Group profit",
      groupRegNum: "Team registrations",
      groupBetUsers: "Number of team bettors",
      groupBetAmount: "Team bet amount",
      groupWinNum: "Number of inferiors who win",
      groupWinAmount: "Win the lower amount",
      groupRechargeAmount: "Team recharge amount",
      groupWithdrawAmount: "Team withdrawal amount",
      betCommission: "Betting commission",
      groupBetCommission: "Team betting commission"
    }
  },
  teamReport: {
    from: "From",
    to: "To",
    quickSelect: "Quick selection",
    groupBalance: "GROUP'S CURRENT BALANCE",
    label: {
      membersNum: "Total number of members",
      rechargeAmount: "Total deposit",
      withdrawAmount: "Total withdraw",
      betNum: "Number of members betting",
      betAmount: "Total bet",
      winNum: "Number of winning members",
      winAmount: "Total win",
      commission: "Commission"
    }
  },
  agentReport: {
    searchPlaceholder: "Search Agent Name...",
    label: {
      total: "Total",
      recharge: "Top up",
      withdraw: "Withdraw",
      bet: "Total Bet",
      commission: "Commission",
      admin: "Admin adjustment",
      win: "Win",
      promotion: "Promotion",
      profit: "Profit"
    },
    formula: "(*) Profit = Total wommomh + Bonus + Commission"
  },
  phoneVerify: {
    label: {
      code: "verification code"
    },
    placeholder: {
      code: "Please enter verification code"
    },
    btn: {
      send: "Send",
      confirm: "Verify"
    }
  },
  game: {
    rule: {
      title: "Instruction",
      close: "Colse"
    }
  },
  "open.result": 'Result',
  "open.hz": "Sum",
  "open.openTime": "Draw Time",
  "login.rememberPassword": "Remember the password",
  "successWithdrawof": "successful withdrawal of",
  "hint.usernameEnter": "please enter user name!",
  "hint.passwordEnter": "Please enter your password!",
  "hint.passwordAgainEnter": "Please confirm your password!",
  "hint.formidEnter": "Please enter the invitation code!",
  "hint.registeRead": "I am over 18 years old and accept the terms and conditions of this website.",
  "hint.read": "Please make sure to read the agreement and try again!",
  "My.PersonalInformation.Password.oldPass": "Please enter old password",
  "mine": {
    "label": {
      rechargeRecord: "Recharge record"
    }
  },
  "recharge.enter": "Enter",
  rechargeAccountEnter: "Please enter the transferer's bank account name",
  "game.typeHz": "sum",
  "game": {
    fiveBall: {
      "0": "first ball",
      1: "seconed ball",
      2: "third ball",
      3: "fourth ball",
      4: "fifth ball"
    }
  },
  "withdraw.currency.label": "Virtual currency",
  "withdraw.label.enterAmount": "Withdrawal Amount",
  "game": {
    "broadcast": {
      title: "Profit real-time broadcast",
      th_tradeUser: "transaction user",
      th_tradeType: "trading method",
      th_winAmount: "profit amount"
    },
    "chooseGameTitle": "Choose Gameplay"
  },
  "recharge.selectCurrency.title": "Select currency",
  "blindbox": {
    "remainTimes": "Number of draws: {__} times",
    "submit": "Draw now",
    "rule": "Lottery Description",
    "service": "Online Services",
    "navigator": "Lucky draw",
    "noticeText": "Congratulations to customer {__} winning {___}",
    "goodLuck": "Good Luck"
  },
  "Dialog.success.title": "Congratulations on your win!",
  "Dialog.error.title": "Sorry you didn't win!",
  "Dialog.success.message1": "Winning bet:",
  "Dialog.success.message2": "Winning amount:",
  "Dialog.error.message1": "Losing bet:",
  "Dialog.error.message2": "Losing amount:",
  "login.welcome": "Welcome!",
  "user.identityVerification": "Completed identity verification",
  "certified":"Certified",
  "underReview":"Under review",
  "failed":"Failed"

}