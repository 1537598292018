export default {
  "signStr": "1001",
  "tab": {
    "my": "Của tôi",
    "home": "Nhà",
    "record": "Lịch sử",
    "grab": "Nhiệm vụ",
    "financing": "Tài chính",
    "make_money": "Nhiệm vụ"
  },
  "newTab": {
    "home": "Nhà",
    "money": "Tiền bạc",
    "shop": "Cửa hàng",
    "order": "Lịch sử",
    "my": "Của tôi",
    "team": "Đội"
  },
  "common": {
    "unrealized": "Đang làm",
    "all": "Tất cả các",
    "distributionTip": "0Delivery0Distributionfee",
    "easeBack": "Dễ dàng",
    "addCarSuccess": "Tham gia shoppings thành công",
    "deleteConfirm": "AreyousuretodeletetheSelectedItem?"
  },
  "My": {
    "download": "Tải ứng dụng",
    "personal": "Thông tin cá nhân",
    "preOrde": "Đơn đặt hàng trước",
    "account": "Chi tiết tài khoản",
    "recharge": "Rechargerecord",
    "withdrawal": "Rút tiền",
    "invite": "Mời bạn bè",
    "announcement": "Sự thông báo",
    "service": "Dịch vụ",
    "team": "TeamReport",
    "quit": "Thoát ra",
    "logoutEvent": "Xác nhận đăng xuất tài khoản",
    "language": "Cài đặt ngôn ngữ",
    "languageButton": {
      "english": "Tiếng Anh",
      "indonesian": "Indonesia"
    },
    "logout": "Đăng xuất",
    "MemberInformation": {
      "SilverMember": "Silvermember",
      "Invitation": "Mã mời",
      "balance": "Số dư tài khoản",
      "Recharge": "Nạp tiền",
      "Withdraw": "Rút tiền",
      "forzenAll": "Frozenmoney"
    },
    "PersonalInformation": {
      "Page": {
        "title": "Thông tin của tôi",
        "Avatar": "Hình đại diện",
        "Nickname": "Biệt Danh",
        "PhoneNumber": "Số điện thoại",
        // "BankAccounts": "Tài khoản ngân hàng", // 银行账户
        "BankAccounts": 'Địa chỉ Ví của tôi',
        "Password": "Mật khẩu",
        "TransactionPassword": "Mật khẩu giao dịch",
        "ReceivingAddress": "Nhận",
        "levelProgress": "Tiến độ cấp độ"
      },
      "portrait": {
        "SetAvatar": "Avatar",
        "button": "Xác nhận"
      },
      "Name": {
        "ModifyNickname": "Sửa đổi Nick Name",
        "title": "Sửa đổi Nick Name",
        "name": "Tên nick",
        "Modify": "Sửa đổi",
        "Please": "Hãy nhập một nickname"
      },
      "BankAccounts": {
        "BankAccounts": "BindingBankCard",
        "YourInformation": "Thông tin của bạn",
        "RealName": "Tên thật",
        "RealNameNote": "PleasesetyourRealName",
        "PhoneNo": "Số điện thoại",
        "PhoneNoNote": "Xin vui lòng",
        "YourBankCard": "YourBankCardInformation",
        "BankAccount": "Tài khoản ngân hàng",
        "BankAccountNote": "PleasesetBankAccount",
        "BankName": "Tên ngân hàng",
        "BankNameData": {
          "option1": "CHOOSEACCOUNTBANK",
          "option2": "Bankcentralasia",
          "option3": "Banknegaraindonesia",
          "option4": "Bankrakyatindonesia",
          "option5": "Bankmandiri"
        },
        "BankBranch": "Chi nhánh ngân hàng",
        "BankBranchNote": "PleasesetBankBranch",
        "MoreInformation": "Thêm thông tin",
        "WhatsApp": "WhatsApp",
        "WhatsAppNote": "PleasesetWhatsAppnumber",
        "Text": "Nhắc nhở: Xin chào, inorderToguaranteEyourrights, PleaseBindTheCorrectCardNumberAndBankInformation.iftheaccountCannotByeveVerdUetoinceRectFilling, ThisPlatformDoShoStakeAresponsItion!",
        "Settings": "Cài đặt"
      },
      "Password": {
        "title": "Sửa đổi mật khẩu",
        "PleaseEnter": "Vui lòng nhập mật khẩu mới của bạn",
        "phone": "điện thoại",
        "NewPhone": "Vui lòng nhập số điện thoại di động của bạn",
        "verificationCode": "Mã xác nhận",
        "verificationCodeNote": "Vui lòng nhập mã xác minh",
        "oldPassword": "Mật khẩu cũ",
        "oldPasswordNote": "Vui lòng nhập mật khẩu mới của bạn",
        "ConfirmPassword": "Mật khẩu mới",
        "ConfirmPasswordNote": "Nhập mật khẩu mới của bạn",
        "Text": "Hãy nhớ mật khẩu, nếu bạn quên mật khẩu, vui lòng liên hệ với bộ phận chăm sóc khách hàng.",
        "ButtonCode": "lấy mã xác minh",
        "Button": "Sửa đổi",
        "verifyPhone": "Vui lòng nhập số điện thoại di động của bạn",
        "PhoneNew": "Vui lòng xác nhận rằng bạn đã hoàn thành"
      },
      "Site": {
        "title": "Nhận hóa điều trị",
        "ReceivingIdentity": "Nhận thông tin",
        "ReceivingAddress": "Nhận thông tin",
        "DetailedAddress": "Địa chỉ chi tiết",
        "DetailedAddressNote": "PLELENTERDETAILEDDDRESS",
        "Set": "Setascommonaddress",
        "Button": "Xác nhận",
        "hint": "Pleaseconfirmthatyouhavefinished"
      },
      "AccountData": {
        "title": "Chi tiết giao dịch",
        "To": "Đến",
        "search": "Tìm kiếm",
        "Cancel": "Hủy bỏ",
        "Semua": "Semuajenis",
        "Penarikan": "Catatanpenarikan",
        "IsiUlang": "Mutasitop-up"
      },
      "RechargeRecord": {
        "title": "Rechargerecord",
        "Recharge": "Rechargeoffline",
        "Online": "Onlinerecharge",
        "Amount": "Số tiền cần nạp:",
        "Order": "OrderNumber:",
        "Status": "Rechargestatus:"
      },
      "WithdrawalRecord": {
        "title": "Rút tiền",
        "withdrawal": "BankcardwithDrawal"
      },
      "InviteFriends": {
        "Invite": "Liên kết mời: Nhấp vào Sao chép",
        "InvitationCode": "Mã mời:",
        "Click": "Sao chép đường link"
      },
      "Announcement": {
        "title": "Sự thông báo",
        "System": "Hệ thốngannouning"
      },
      "TheTeamReport": {
        "title": "TeamReport",
        "To": "Đến",
        "search": "Tìm kiếm",
        "Cancel": "Hủy bỏ",
        "balance": "TEAMAMOUNT",
        "turnover": "TeamRecharge",
        "recharge": "TeamorderCommission",
        "withdrawal": "TeamWithDrawal",
        "commission": "TeamorderCommission",
        "charge": "TotalNumberOfpeople",
        "people": "NumberOfusersWithRecharge",
        "members": "NumberOfNewusers",
        "NewPeople": "NewuserRecharge",
        "Active": "NumberOfNewusers",
        "Level1": "Cấp độ 1",
        "Level2": "Cấp độ 2",
        "Level3": "Cấp 3"
      }
    },
    "PlatformIntroduction": {
      "PlatformIntroduction": "PlatformIntrial",
      "Two": "ThisplatformisanallianceComosesofmillionsoftraders.itsserviceprincipleis: ThePlatformTradersIncreasethetradingVolume, vàtheplatformMemembereArnCommissionincomebyReCeIningerS.",
      "Three": "Thethird-partyIntelligentCloudcontrolsthehostordermatchingplatform.Theplatformcooperateswithotheronlineshoppingplatformmerchantstomatchbusinessorderswithplatformusersthroughintelligentcloudsystem,soastoincreasethetransactionvolumeandsellerreputationonthemainplatform.Thedeliverymethodofthepurchaseorderisimproved.Theorderisautomaticallysentbytheremoteintelligentcloudserver.Theplatformusersonlyneedtoprovidethecompletereceiveraddress,telephonenumberandsomeshoppinginformationdirectlyontheplatformtoenterthesmartcloudhost.",
      "Four": "Inordertohelpsellerseffectivelyimprovethesuccessrateofordersandavoidtheriskofsellerscheckingfalseorders,theplatformwilloptimizetheordermatchingofthedayaccordingtoyourcurrentoperatingIPandequipmenttype.Therefore,ordermatchingneedstoberealizedbyintelligentcloudcomputingalgorithm.Pleasewaitpatiently.Theplatformuserswillautomaticallybecometheplatformagentbypromotingnewpeopletojointheplatform,andtheycangetextracommission.",
      "Five": "YoujustneedtouseYoursParetimetosurftheInet, Andyoucaneasilyearnrichcommissioneveryday.AfteryouClickAndsuccessSendtheOrder, ThePrincipalandCommissionWillBereFunded."
    },
    "RuleDescription": {
      "RuleDescription": "Quyết định",
      "One": "1.anggotaharusmengisialamatpenerimadanmeningkatkaninformasipribadisebelummengambilpesanan.komisiange",
      "Two": "2.Karenapesananbarangmemerlukanwaktutertentuuntukberlaku,pesananhanyadapatditerimadenganpenjualsetelahpesananberlaku,olehkarenaitupenyelesaiankomisiplatformdanbanktripartitdananggotamenggunakansistemT+1.Yaitu:hariiniuntukmenarikdanuangakanditerimapadakeesokanharisebelum23:59.Jikapesanangagalberlakusetelahwaktuyangditentukan,platformakanmembayardiuangmuka,menyelesaikanakundanmengeluarkanpembayarandalamwaktuyangditentukan.",
      "Three": "3.Kondisiaulayangsesuadanrasiokomisianggotaperak: semuaanggotasilverdapatmengambil10pesananperhari, dankomisiuntukpesanantersebutadalah0,30%",
      "Four": "Anggotaemas: Untukmembukukaanggotaemas, Andaperlumengundanghingga3oranguntukmasukkeaulaini.andadapatmengambil35pesananperhari, dankomisiuntukpesananadalah0.35%",
      "Five": "Aygotaplatinum: Untukmembukaanggotaplatinum, andaperlumengundanghingga10oranguntukmemasukiaulaini.andabisamendapatkan40pesanperhari, dankomisiuntukpesan",
      "Six": "Aygotaplatinum: Untukmembukaanggotaplatinum, andaperlumengundanghingga10oranguntukmemasukiaulaini.andabisamendapatkan40pesanperhari, dankomisiuntukpesan",
      "Seven": "AygoTaberlian: Untukmembukaanggotadiamond, Andaperlumengundang30oranguntukmemasukiaulaini.andadapatmemperoleh45pesananperhari, dankomisiuntukpesantersebutadalah0,45%",
      "Eight": "4.HarapperiksanomorkartudepositplatformsebelumMENT",
      "Nine": "Catatan: Silakanperiksainformasikartubankdariplatformapakahituplatforminiuntuksetiaptransferuntukmenghindarikerugianekonomianda.",
      "Ten": "5.Untukmelindungiprofitantarapenjualdanplatform,olehkarenaitusetiapip,nomorkartubankdannomorponselhanyadapatberlakuuntuksatuakun.Semuaplatforme-niagautamamemilikimesinkontrolrisiko,Jikabanyakpenggunadiareayangsamadanipyangsamayangberbelanjaditokoyangsama,akanmenyebabkanprodukbahanpeledaktokodihilangkandanmempengaruhikredibilitasdanpenjualanpenjualtidakvalid.",
      "Eleven": "Các công ty, pelanggarakanpermekukanakunakunaperaper, achanggarakanmembekukanakunakunaudayankan, achanggarakanmembekukanakunakunapek đã ratankankankankankankankankankankankankankankankankankankankankankankankankankankankan, ALO",
      "OneData": {
        "One": "Pengenalantugas:",
        "Two": "Jenistugastarifkomisi",
        "Three": "Lazada0.30%",
        "Four": "Bukalapak0,35%",
        "Five": "Shopee0,40%",
        "Six": "Tokopedia0,45%"
      },
      "TwoData": {
        "One": "DeskRipsitingKatKeanGgotaan:",
        "Two": "Jeniskeanggotaananggotaperak",
        "Three": "Bataspenarikan50.000.000",
        "Four": "Jumlahpesanan30/Hari",
        "Five": "Tingkatkomisi0.30%",
        "Six": "Không thay đổi-"
      },
      "FourData": {
        "One": "Jeniskeanggotaananggotaplatinum",
        "Two": "Bataspenarikan50.000.000",
        "Three": "Jumlahpesanan40/hari",
        "Four": "Tingkatkomisi0,40%",
        "Five": "Undang10"
      },
      "FiveData": {
        "One": "Jeniskeanggotaananggotaberlian",
        "Two": "Bataspenarikan50.000.000",
        "Three": "Jumlahpesanan45/hari",
        "Four": "Tingkatkomisi0,45%",
        "Five": "Undang30"
      },
      "Twelve": "*Levelyangberbedadapatmemperolehangkaganjildanrasiokomisi.semakintinggilevelkeangaan, semakinbanyakangkaganjilyangbisaandaambil.semakinbesarurutyny"
    },
    "AgentCooperation": {
      "AgentCooperation": "Đại lý",
      "One": "Peluncuranstandartingkatketigauntukproxy",
      "Two": "Aygotadapatmenjadiproxyplatformdanganmerekomendasikananggotabaruyangbergabunguntukmendapatkanhadiahtambahan.",
      "Three": "Promosikanlangsunghadiahpenggunatingkatpertamauntukmendapatkankomisibonus9%setiaphari.",
      "Four": "Sebagaicontoh:",
      "Five": "ProksitingkatpertamaAndatelahmengundang10oranguntukbergabungmenjadianggota,proxytingkatkeduamengundang20oranguntukbergabungmenjadianggotadanproxytingkatketigatelahmengundang40oranguntukbergabungmenjadianggota.Pendapatanperoranguntuksehariadalah200.000INR,jadibayangkansajapenghasilanharianyangakanAndadapatkan.",
      "Six": "Levelpertama: 200.000x10x9%= 180.000inr",
      "Seven": "Tingkatkedua: 200.000x20x6%= 240.000inr",
      "Eight": "Tingkatketiga: 200.000x40x3%= 240.000inr",
      "Nine": "Levelpertama+tingkatkedua+tingkatketiga = 660.000inr",
      "Ten": "MisalkanandAndamengundanggotaauntukmendaftar, Amengundangb, Danbmengundangc,",
      "Eleven": "Kemudiananggotaaadalahpenggunatingkatpertamatimanda, badalahpengunatingkatkeduaanda, dancadalahpenggunatingkatketigaanda.",
      "Twelve": "Jumlahhadiah = komisipesananax9%+komisipesananbx6%+komisipesanancx3%.",
      "Thirteen": "*Pengingat: Hadiahkomisiuntuksemuaaagenyangmerupakananggotaofflinedikeluarkanolehplatformini, DantidakakanmemEngaruhikomisisemuaaagendananggotaoffline!"
    },
    "CommonProblem": {
      "CommonProblem": "Vấn đề thường gặp",
      "One": "Q: Mengapabisnismembutuhkanpesananvirtual?",
      "Two": "Menurutaturanplatforme-commerceutama,semakinbanyakorangmembeliproduk,semakinpopulerproduktersebut,dansemakinbanyakpeluangakandidorongdanditampilkanolehplatforme-commerce.Bisnise-commercesedangsengit.Untukmeningkatkaneksposurproduk,pedagangperlumeningkatkanvolumetransaksikomoditas,sehinggamerekamemilihuntukmenghasilkanpesananvirtualdiplatform.",
      "Three": "Q: Bagaimaanacaramenyelesaikanprosespengambilanpesanan?",
      "Four": "Pilihsalurane-niaga,klikuntukmengambilpesanansecaraotomatis,sistemdengancerdasmencocokkanprodukuntukmendapatkanpesanan.PlatformmenggunakaninformasipribadiAndayangsebenarnya,alamatpengiriman,danIPjaringanuntukmengirimkanpesanankeplatforme-commerceuntukmensimulasikanbelanjaonline.Namun,pedagangtidakbenar-benarmengirimkanbarangnya.Setelahpesananberhasildikirim,pokokdankomisipesananakansegeradikembalikankeakunAnda.",
      "Five": "Q: Mengapasayaperlumengisiulangsebelummenerimapesanan?",
      "Six": "Untukmemastikankeaslianpesanan, setiapplatformpemeananyangdisimulasikanmemerlukanpembayarannyatesakesituswebe-niaga.t",
      "Seven": "Q: Bagaimaanacarameningkatkanjumlahpesananbildanmeningkatkankomisi?",
      "Eight": "DenganMengundangtemanuntukmendaftar, semakintinggilevelnya, semakinbanyakjumlahtotalpesanperhari, dansemakintinggirasiokomisi;",
      "Nine": "Q: Bagaimaanacarengundangtemanuntukmendaftar?",
      "Ten": "Klik \"Undangteman\", Salintautanberbagiataukodeqr, Dankirimkanketeman, Temansekelas, Kolega, Dankeluargaandamelaluaperangkatlunaksosial.andaharusmengisikodeundanganunt",
      "Eleven": "Q: Bagaimaanacaramenarikuangtunai?",
      "Twelve": "Andadapatmengajukanpenarikantunaisetelahmemenuhikuantasminimumorder.jikasyaratkuantasminimumordertidakter"
    },
    "MemberUpgrade": {
      "MemberUpgrade": "Thành viên",
      "Member": "Thành viên ：",
      "Orders": "Chi tiết nhiệm vụ",
      "Commission": "Hoa hồng",
      "DedicatedOne": "Chuyên dụngCustomerservice",
      "DedicatedTwo": "Chuyên dụngCustomerservice",
      "Swipe": "Swipeleftandrighttoseemore",
      "One": {
        "Silver": "Silvermember",
        "Need": "NeedToinvite0people",
        "Withdrawal": "Rút tiền: 50.000.000",
        "quantity": "OrderQuantity: 10/ngày",
        "rate": "Ủy ban: 0,30%",
        "fee": "Rút tiền: 2,00%",
        "Activated": "Kích hoạt"
      },
      "Two": {
        "Silver": "Thành viên vàng",
        "Need": "NeedToinvite3people",
        "People": "3Peoplestillshort",
        "Withdrawal": "Rút tiền: 50.000.000",
        "quantity": "OrderQuantity: 35/ngày",
        "rate": "Ủy ban: 0,35%",
        "fee": "Rút tiền: 2,00%",
        "Activated": "Dailyrechargeamount: 1.000.000",
        "Invite": "Invitenow"
      },
      "Three": {
        "Silver": "Platinummember",
        "Need": "NeedToinvite10People",
        "People": "10PeapleStillShort",
        "Withdrawal": "Rút tiền: 50.000.000",
        "quantity": "OrderQuantity: 40/ngày",
        "rate": "Ủy ban: 0,40%",
        "fee": "Rút tiền: 2,00%",
        "Activated": "Dailyrechargeamount: 1.500.000",
        "Invite": "Invitenow"
      },
      "Four": {
        "Silver": "Diamondmember",
        "Need": "NeedToinvite30people",
        "People": "30PeapleStillShort",
        "Withdrawal": "Rút tiền: 50.000.000",
        "quantity": "OrderQuantity: 45/ngày",
        "rate": "Ủy ban: 0,45%",
        "fee": "Rút tiền: 2,00%",
        "Activated": "Dailyrechargeamount: 2.000.000",
        "Invite": "Invitenow"
      }
    },
    "PhoneNew": "Pleaseconfirmthatyouhavefinished",
    "Toast": {
      "One": "Lưu lại thành công",
      "Two": "NotSucceed"
    },
    "Button": {
      "One": "Hủy bỏ",
      "Two": "Xác nhận"
    }
  },
  "Record": {},
  "Grab": {},
  "Financing": {
    "time": "Thời gian",
    "day": "Ngày",
    "Fina": "Tài chính",
    "assets": "Tổng tài sản",
    "amount": "Tài chính",
    "revenue": "Tổng số tiền rút",
    "earnings": "Ngày hôm qua",
    "Balance": "Chuyển khoản Số dư",
    "RP": "",
    "Please": "PLELINPUTTHETRANSFERINAMOUNTSILAKANMASMAKKANTRANSFERDALAMJUMLAH",
    "Estimated": "Ước tínhincome",
    "Income": "Incomestandard",
    "TransferOut": "Rút tiền",
    "ransferIn": "Chuyển giao",
    "SukuBung": "Sukubunga",
    "Transfer": "Transferinfailed",
    "Pleaseint": "PleaseNentertheamount",
    "Successful": "Thành công",
    "RansferInSU": {
      "FinancialAcc": "FinancialAccessRecord",
      "Acc": "AccessLog"
    },
    "ServiceSu": {
      "Please": "Làm ơn ...",
      "send": "gửi đi",
      "Online": "Dịch vụ trực tuyến",
      "Service": "Dịch vụ",
      "Customer": "Pelayananpelanggan",
      "Ifyou": "Ifyouencerseproblemsandneedhelp, pleasecontactonLineCunStomerservic",
      "Layanana8": "Khách hàng bảo vệ",
      "Layanana9": "Khách hàngerviceCenterno.9 (WhatsApp)",
      "Dedicated": "Chuyên dụngCunStomerservice",
      "Telegram8": "Layananapelangganno.8 (Telegram)",
      "Telegram008": "Telegram008",
      "Telegram9": "Layananapelangganno.9 (Telegram)",
      "Telegram009": "Telegram009"
    }
  },
  "Home": {
    "HallofFame": "Danh sách trúng thưởng",
    "Member": "Thành viên",
    "Mission": "Missionhall",
    "Learnde": "LearnDe",
    "Hello": "Xin chào",
    "Let": "LetVictoryMallopenthewayTowealth",
    "total": "Mytotalassets",
    "Withdraw": "Rút tiền",
    "Recharge": "Nạp tiền",
    "RP": "",
    "earningsRp": "Ngày hôm qua",
    "incomeRp": "Tích lũy",
    "rarningsRp": "Hôm nay là",
    "Service": "Dịch vụ",
    "Invite": "Mời",
    "Lazada": "Lazada",
    "Bukalapak": "Bukalapak",
    "Shopee": "Cửa hàng",
    "Tokopedia": "Tokopedia",
    "silver": "Sonelforsilvermembers",
    "gold": "ChannelForGoldMembers",
    "platinum": "sonelforplatinummembers",
    "diamond": "ChannelFordIAmondMembers",
    "Agent": "AgentorderCommission",
    "Order": "Thứ tự",
    "RechargeSu": {
      "Recharge": "Nạp tiền",
      "Catatan": "Catatankhusus:",
      "Next": "Tiếp theo",
      "RechargeAm": "Số tiền cần nạp",
      "Enter": "Entertherechargeamount",
      "Quick": "Sự lựa chọnnhanh",
      "nameP": "PLELENTERYOURREALNAME",
      "name": "Tên",
      "Pleaseamount": "PleaseNentertheamount",
      "Pleaseint": "PleaseNentertheamount",
      "Pleasename": "Xin hãy nhập tên của bạn"
    },
    "WithdrawSu": {
      "Withdraw": "Rút tiền",
      "Withdrawal": "Số tiền rút",
      "WithdrawalRe": "Rút tiền",
      "Input": "InputWithDrawAlamount",
      "Balance": "Số dư",
      "WithdrawAll": "Rút tiền",
      "Mobile": "MobilePhonenumbe",
      "Bank": "BankcardNumber",
      "Owned": "Sở hữuBank",
      "Account": "Tên tài khoản",
      "Transaction": "Mật khẩu giao dịch",
      "Withdrawim": "Rút tiền",
      "Pleaseamount": "PleaseNentertheamount",
      "Pleasephone": "Xin vui lòng điền số điện thoại của bạn",
      "Pleasecard": "PLELENTERYOURBANKCARDNUMBER",
      "Pleasebank": "PLELENTERYOURBANKNAME",
      "Pleasename": "Xin hãy nhập tên của bạn",
      "Pleasepassword": "PLELENTERTRATRTRATECTIONPASSWORD",
      "Pleaseint": "PleaseNentertheamount",
      "PasswordError": "Sai mật khẩu",
      "Successful": "Đã áp dụng forpitwithDrawal",
      "Insufficient": "Không đủWithDrawAlalBalance",
      "Unbound": "TheBankcardhasnotbeenbound",
      "Binding": "BoundyourBankcard",
      "idCard": "Số chứng minh nhân dân"
    }
  },
  "Login": {
    "indexTitle": "Đăng nhập",
    "remenber": "Ghi nhớThepassword",
    "versions": "V.86",
    "masuk": "Đăng nhập",
    "name": "Tải xuống ứng dụng",
    "forgot": "Quên",
    "registrasi": "Đăng ký",
    "loginButton": "Đăng nhập",
    "nameInput": "Nhập tên tài khoản ",
    "pwdInput": "Nhập mật khẩu",
    "notLoginHint": "Đăng nhập",
    "goLoginText": "xác nhận",
    "Forgot": {
      "title": "Truy xuất ba lần",
      "registerTitle": "Đăng ký",
      "phone": "Nhập số điện thoại của bạn",
      "code": "Nhập mã xác nhận",
      "area": "PleaseNenterArecoDE",
      "sendCode": "gửi mã",
      "password": "Mật khẩu",
      "againPassword": "Xác nhận mật khẩu",
      "confirm": "Xác nhận",
      "goBack": "Bạn đã có tài khoản ? Đăng nhập ngay",
      "register": "Daftar"
    },
    "Daftar": {
      "username": "Tên tài khoản",
      "title": "Đăng ký",
      "area": "PleaseNenterArecoDE",
      "phone": "Số điện thoại",
      "code": "Nhập mã xác nhận",
      "userName": "Tên tài khoản",
      "password": "Mật khẩu",
      "againPassword": "Xác nhận mật khẩu",
      "payPassword": "Paypasspassword",
      "referrer": "Mã lời mời (ID)"
    }
  },
  "Cart": {
    "title": "Chi tiết nhiệm vụ",
    "explain": "Sự mô tả",
    "grade": {
      "SliverMember": "Bạc",
      "GoldMember": "Thành viên",
      "PlatinumMember": "Vàng",
      "DiamondMember": "Thành viên",
      "undefined": "Thành viên"
    },
    "info": {
      "allMoney": "Tổng số của tôi",
      "dayAllOrder": "Một bộ",
      "dayTrustMoney": "Thu nhập hôm nay",
      "yesterdayMoney": "TotalOrder",
      "undefined": "thu nhập = earnings"
    },
    "text": {
      "rebateAndPrincipal": "Trở về",
      "residue": "thủ đô",
      "one": "Lịch sử",
      "rebate": "Lợi nhuận",
      "buyBtn": "Còn lại",
      "toGet": "Lịch sử",
      "orderSuccess": "Trả lại",
      "orderGetMoney": "Mua ngay",
      "orderWaiting": "Akanmendapat",
      "orderOver": "Chi tiết nhiệm vụ",
      "undefined": "Xong"
    },
    "state": {
      "all": "Tất cả các",
      "going": "Nữ sinh",
      "paid": "Trả",
      "frozen": "Thông tin",
      "over": "Xong"
    },
    "zzInfo": {
      "buyIng": "Thu mua",
      "buySucceedFindDT": "ClickthestatusviewAfterThePurchase",
      "ok": "là thành công",
      "buyFailed": "Được chứ",
      "notLevel": "Giao dịch thất bại",
      "buyJurisdiction1": "YourVipLevelisInsuff đủ",
      "buyJurisdiction2": "Ifwantpurchaseagoldmembershiporde",
      "buyJurisdiction3": "IFWANTPURCHASEALLATINumMMBERSHIPHIPorder",
      "meet": "IFWANTPurchaseadAmondMembershipReater",
      "invite1": "OneOfThefollowingConditionsmustblefulfills",
      "invite4": "Mời3 FriendStomAKemoneyTogether",
      "invite3": "Mời10 FriendStomAKemoneyTogether",
      "buySendMoney": "Mời30 FriendstomAKemoneytogether",
      "invite2": "2.Spendrs2000ToBUyMembershipdiamond",
      "buy": "Mời bạn bè",
      "myMoney": "Mua ngay",
      "under": "YourAccountBalance",
      "notSendMoney": "Phía dưới",
      "goTopUp": "CantDotransaction",
      "know": "PLELGOTOP-UP",
      "account": "Settlement",
      "undefined": "Tôi biết"
    },
    "hint": {
      "p1": "1",
      "p2": "2.thehigheryourMembershipLevel, TheHigherTheOrderCommissionamountthatcanbepurchasing",
      "p3": "3.forPurchasingReSter, YouCanMonitorTheRtatusanyTime"
    }
  },
  "Hint": {
    "SendCodeSuccess": "TheverificationCodeissentsuccess một cách một cách khó khăn!",
    "closed": "Đóng",
    "notTime": "Các ghi chú được chọn",
    "notTimeFailed": "Thời gian chọn",
    "success": "Thành công",
    "failed": "Thất bại",
    "phoneNot": "NoHP/Noakuntidakbisakosong",
    "pwdInconformity": "Mật khẩu không có",
    "notMoney": "MoneyInsufficent",
    "notEvent": "Featurecantuseyet",
    "notInput": "Không hoàn chỉnh",
    "loading": "Đang tải",
    "notMore": "khôngThêm",
    "notPhone": "Tài khoản không phải là chủ nghĩa",
    "yesPhone": "Tài khoản",
    "levelNot": "Cấp độ",
    "serverMaintenance": "Xin vui lòng, Thiếu tá",
    "invite": "Mời bạn bè",
    "goodsNumNo": "kiếm tiền",
    "haveBuy": "cùng với nhau",
    "depositBtnText1": "Không đủ điều kiện",
    "depositBtnText2": "Đã mua",
    "depositBtnText3": "Diterima",
    "commissionMoney": "Có sẵn",
    "bill": "Nhận",
    "billRecordTitle": "Trả lại thông thường:",
    "balance": "THĂNG BẰNG",
    "reference": "Dán mã lời mời",
    "updateText": "Vui lòng cập nhật lên phiên bản mới nhất",
    "noData": "Không có dữ liệu"
  },
  "newAdd": {
    "all": "Tất cả",
    "Completed": "Hoàn thành",
    "unfinished": "Chưa xong",
    "GetCodeBtn": "GetverificationCode",
    "copySuccess": "Copysuccess!",
    "copyError": "CopyTheFailure!",
    "noLoginUserName": "Đăng nhập",
    "Withdraw": "Rút tiền",
    "Recharge": "Nạp tiền",
    "commission": "Lợi nhuận",
    "single": "Độc thân",
    "missionHall": "Missionhall",
    "videoTutorial": "Video hướng dẫn",
    "helpCenter": "Trung tâm trợ giúp",
    "inviteFriends": "Mời bạn bè",
    "play": "Bắt đầu chơi",
    "help1Title": "HowToearnCommissionSwithMudo",
    "help1Context": "Thương mại điện tử",
    "help2Title": "Howtoincreasethenumberofmission",
    "help2Context": "Becausetherearetoomanypart-thời gian làm việc, thecompanystipulatesthatallonlinepart-timeMployeesusetheonlyWhatsApptogetInToughandReceIvemission.",
    "help3Title": "HowToCompletEthemission",
    "help3Context": "itistohelpmajormerchantstoincreasesales, andtorewardyouactiontothenumberoftransactions.",
    "referralLink": "Giới thiệu.",
    "copy": "sao chép",
    "blance": "Số dư",
    "todayOrder": "Hôm nay là thứ tự",
    "provided": "Thedataisprovidedby",
    "Transaction": "Giao dịch",
    "Frozen": "Thông tin",
    "Done": "Xong",
    "Cancel": "Hủy bỏ",
    "ExpectedReturn": "Reteturn dự kiến:",
    "ImmediatePayment": "Ngay lập tức",
    "AvailableNum": "Therearestill",
    "OrderImmediately": "Tìm nhiệm vụ",
    "MakeMoneyEveryDay": "Makemoneyeveryday",
    "TodayRecord": "Hôm nay là rrecord",
    "AllocatCommissions": "Phân bổOfCommissions",
    "FrozenAmount": "Frozenamount",
    "TaskMatch": "TaskorderRandomness",
    "AvailableBalance": "Số dư",
    "WaitDispatch": "Chờ đợifordispatch, Pleasecontactthestoremanager!",
    "OrderDescription": "Chi tiết nhiệm vụ",
    "CouldMatch": "Nhiệm vụDomerAndomlyMatchedBythesystem",
    "OrderPerDay": "Độc thân.",
    "DescriptionRate": "OrderPlocationCommissionisUniformly",
    "DescriptionPeriod": "OftheOrderAmount.",
    "Description3": "ThesystemisbasingonlbstechnologyAndautomallyMatchesproductsthroughthecloud.",
    "Description4": "InorderTopreventthesupervisionoftheplatform, iftheorderisnotconfirmedandsubmitedwithin20minutes, thesystemwillfreezetheorderamount.",
    "Note": "Ghi chú:",
    "DescriptionNote": "Whentheplatformmatchestheordertotheuser,theplatformsubmitstheinformationoftheordertothemerchantbackend.Iftheuserdoesnotsubmittheorderwithin20minutes,theamountoftheorderwillbefrozenbythesystem.Inordertocircumventthesupervisionsystem,useraccountswillbescored,whichwilldirectlyaffectthetimeandreputationoftheuser'snextordermatching!Ifthereareotherthingsthatneedtobedealtwith,pleaseclicktostoptheorderintimetoavoidfreezingduetotimeout",
    "OrderNum": "OrderNumber:",
    "TotalOrderPrice": "TotalOrderPrice",
    "SubmitNow": "Xác nhận ngay bây giờ",
    "Record": "Lịch sử",
    "MemberAccount": "Thành viên",
    "AccountPlaceholder": "PLELENTERYOURMEMMENCOUNT",
    "RechargeAmount": "Số tiền cần nạp",
    "RechargeAmountPlaceholder": "Nhập số tiền",
    "SelectRechargeMethod": "Chọn phương thức để thanh toán",
    "NotBeenSentOrder": "TheorderHasNotbeensent, PleasecontactthestafftosendtheOrder!",
    "TodayOrderOver": "ContactonLineCustomerservice",
    "NotLogin": "Youarenotloggedin, Pleaseloginandtryagain!",
    "HaveNoteMethod": "ATHERiSnosuchRechArgemethod, Pleasechooseanothothod ~",
    "Login": "Đăng nhập",
    "AccountRecord": "Thu nhập và chi tiêu",
    "RealName": "Tên tài khoản",
    "IFSCCODE": "IFSCCODE",
    "BankName": "Tên ngân hàng",
    "ChainType": "Chaintype",
    "BankNum": "Số tài khoản ngân hàng",
    "Mobile": "Di động",
    "MobileNum": "Số điện thoại",
    "Email": "E-mail",
    "EmailRequired": "Email thì cần thiết",
    "AddBankCardNote": "Thông tin rút tiền phải được điền chính xác",
    "AddWalletNote": "Lưu ý: WalletAddressisimportantInformation, PleaseFillitOutcare!",
    "Submit": "Xác nhận",
    "AddBankCardTitle": "Thêm ngân hàng",
    "AddWalletTitle": "AddWalletAddress",
    "BankCard": "Thẻ ngân hàng",
    "WalletInfo": "Walletinfo",
    "Name": "Tên",
    "CardNum": "Số thẻ ngân hàng (stk)",
    "WalletAddress": "Địa chỉ nhà",
    "Reading": "isbeingreading ...",
    "AllBalance": "Số dư",
    "EnterWithdrawalAmount": "Vui lòng nhập số tiền bạn muốn rút",
    "AllOut": "Tất cả đi ra",
    "AskNow": "Xác nhận",
    "EnterPwdPlaceholder": "Vui lòng nhập mật khẩu",
    "NotHaveCard": "Bạn vẫn chưa đăng ký tài khoản ngân hàng.bạn có muốn đăng ký không?",
    "NotHaveWallet": "Bạn chưa thiết lập địa chỉ ví, bạn có muốn vào phần cài đặt không?",
    "GetBankInfoFail": "Không truy xuất được thông tin ngân hàng",
    "EnterValidPayPwd": "Vui lòng nhập mật khẩu thanh toán hợp lệ!",
    "Balance": "Số dư",
    "Total": "Tổng cộng",
    "total": "toàn bộ",
    "records": "Hồ sơ",
    "DisplayPerPage": "mỗi người",
    "NullNum": "",
    "pages": "trang.",
    "toAudit": "kiểm toán",
    "succeed": "thành công",
    "RechargeRecordTitle": "Lịch sử",
    "rests": "nghỉ ngơi",
    "Arrive": "Đến",
    "Income": "Thu nhập",
    // Thanh toán
    "Expenditure": "Thanh toán",
    "forzenCommission": "không có người nhận",
    "companyIntro": "Công ty",
    "companyTitle": "Tata Mall trực thuộc Tập đoàn Tata",
    "warmTitle": "Chú ý",
    "companyText": "Tập đoàn Tata là tập đoàn kinh doanh lớn nhất Ấn Độ, được thành lập năm 1868 và có trụ sở chính tại Mumbai, Ấn Độ. Hoạt động kinh doanh liên quan đến bảy lĩnh vực kinh doanh: truyền thông và công nghệ thông tin, kỹ thuật, vật liệu, dịch vụ, năng lượng, sản phẩm tiêu dùng và sản phẩm hóa chất. Là một tập đoàn kinh doanh đang phát triển nhanh chóng ở Ấn Độ, Tata Group có hơn 200 công ty con, hoạt động tại hơn 80 quốc gia trên 6 lục địa và xuất khẩu sản phẩm và dịch vụ sang 85 quốc gia. Tổng doanh thu của Tập đoàn Tata trong năm tài chính 2020-2021 là 100,09 tỷ USD, trong đó 58% đến từ các hoạt động ở nước ngoài. Số lượng nhân viên của Tập đoàn trên khắp thế giới xấp xỉ 450.000 người. Từ 153 năm nay, cái tên Tata ở Ấn Độ đã được mọi người kính trọng vì sự tuân thủ các giá trị tốt đẹp và đạo đức kinh doanh. Được Forbes xếp hạng là công ty có uy tín thứ 11 trên thế giới.",
    "depositAddress": "Địa chỉ gửi tiền",
    "rechargeSteps": "Các bước nạp tiền",
    "completePayment": "Hoàn thành thanh toán",
    "rechargeNow": "Nạp tiền ngay bây giờ",
    "rechargeCancel": "Hủy thanh toán",
    "bankName": "Tên ngân hàng",
    "payee": "Người nhận tiền",
    "cardNum": "Số thẻ ngân hàng",
    "reviewing": "Đang xem xét",
    "remittance": "Thành công",
    "notThrough": "Thất bại",
    "OrderID": "ID đặt hàng",
    "Amount": "số lượng",
    "toCutScreen": "Vui lòng chụp ảnh màn hình nếu hình ảnh không có sẵn!",
    "cardTabBank": "Ngân hàng",
    "cardTabUSDT": "USDT",
    "name": "Tên",
    "chainType": "Loại dây chuyền",
    // 这下面是新增 2022-11-30 的
    newNavTitle: 'Gửi đơn hàng',
    shopMoney: 'Số lượng hàng hóa', // 商品金额
    actualMoney: 'Gửi thực tế', // 实际提交金额（客户手动填写）
    commitBtnText: 'Tạo đơn đặt hàng',
    commitHint: 'Đầu vào không đầy đủ' // 输入不完整    
  },
  "mine": {
    "servier": "Dịch vụ",
    'integral': 'tích phân', // 积分展示
    'balance': 'Đổi trả', // 兑换
    'record': 'Lịch sử đổi', // 兑换记录
    "wtdAmt": 'Đổi điểm',
    avaBlc: 'Số lượng đổi', // 兑换数量
    allWtd: 'Đổi tất cả', // 全部兑换
    close: 'Hủy',
    commit: 'Xác nhận',
    wtdHint: 'Vui lòng nhập đúng số tiền rút', // 请输入正确的提现金额
    label: {
      rechargeRecord: "Hồ sơ nạp tiền"
    }
  },
  "personal.page.email": "E-mail",
  "personal.page.phone": "Số điện thoại",
  "users.email": "E-mail",
  "users.userphone": "Số điện thoại",
  "users.username": "Tên tài khoản",
  "add": {
    "Commission": "Nhiệm vụ",
    "Yesterday": "Hôm qua",
    "Cumulative": "Tích lũy",
    "Today": "Thu nhập hôm nay",
    "TeamNum": "Tổng quy mô nhóm",
    "TeamMoney": "Tổng lợi nhuận của nhóm",
    "Personalcenteraccountchangerecord": "Tài khoản",
    "lockedbalance": "khóa chặt",
    "Cumulativeinvitation": "Tích lũy",
    "Validinvitation": "Lời mời hợp lệ",
    "Accumulatedrebate": "Tích lũy"
  },
  "home.btn.wallet": "Tài chính",
  // "home.btn.recharge": "Nạp tiền",
  "home.btn.recharge": "Nạp tiền",
  "home.btn.withdraw": "Rút tiền",
  "home.btn.invite": "Mời",
  "page.grab": "Nhiệm vụ",
  "orderCommission": "Nhiệm vụ lợi nhuận",
  "commission": "Lợi nhuận",
  "todayIncome": "Thu nhập hôm nay",
  "personalEarnings": "Thu nhập cá nhân",
  "completedOrders": "Hoàn thành Nhiệm vụ",
  "lockedOrders": "Số tiền bị khóa",
  "unfinishOrder": "Không hoàn thành",
  "frozonAmount": "Số tiền chưa hoàn thành",
  "currentBalance": "Số tiền hiện tại",
  "page.mine": "Chi tiết tài khoản",
  "mine.invitationCode": "Mã mời",
  "mine.serverCenter": "Máy chủ",
  "mine.tools.total": "Tổng cộng",
  "mine.tools.financial": "Tài chính",
  "mine.tools.details": "Thông tin chi tiết",
  "mine.tools.freeze": "Thông tin",
  "mine.serviceTools.personal": "Riêng tư",
  "mine.serviceTools.teamReport": "Báo cáo nhóm",
  "mine.serviceTools.invite": "Tài khoản gia đình",
  "mine.serviceTools.message": "Thông điệp",
  "mine.serviceTools.aboutUs": "Về chúng tôi",
  "mine.serviceTools.security": "Bảo vệ",
  "mine.serviceTools.address": "Địa chỉ nhà",
  "mine.serviceTools.inviteTask": "Mời Nhiệm vụ",
  "page.team": "Đội",
  "page.aboutus": "Về chúng tôi",
  "page.financial": "Tài chính",
  "page.financialRecord": "Hồ sơ tài chính",
  "hint.InternetErr": "Lỗi mạng, vui lòng thử lại sau!",
  "totalDeposit": "Tổng số tiền nạp",
  "totalRevenue": "Tổng số tiền rút",
  "btn.transferInto": "Nạp tiền",
  "btn.transferOut": "Rút tiền",
  "page.transferInto": "Nạp tiền",
  "page.transferOut": "Rút tiền",
  "balanceTransfer": "Chuyển khoản Số dư",
  "availableBalance": "Số dư",
  "depositRate": "Lãi suất mỗi tháng",
  "days": "ngày",
  "hint.success": "Thành công",
  "hint.fail": "Thất bại",
  "status.into": "Nạp tiền",
  "status.out": "Rút tiền",
  "label.amount": "Số lượng",
  "label.time": "Thời gian",
  "Button": {
    "cancle": "Hủy bỏ",
    "confirm": "Xác nhận"
  },
  "dialog.title.inputPaypass": "Nhập mật khẩu thanh toán",
  "dialog.nopaypass": "Mật khẩu thanh toán chưa được đặt, bấm OK để chuyển sang cài đặt!",
  "mine.serviceTools.lan": "Ngôn ngữ",
  "checkLans": "Chuyển đổi ngôn ngữ",
  "mine.tools.commission": "Nhiệm vụ",
  "page.commissionRecord": "Nhiệm vụ",
  "noticeList.all": "Tất cả các",
  "noticeList.unread": "Chưa đọc",
  "successWithdrawof": "Rút tiền thành công",
  "home.title.notice": "Chú ý",
  "home.title.patener": "Bạn đồng hành",
  "login.rememberPassword": "Nhớ mật khẩu",
  "login.userAgreement": "Thỏa thuận về người dùng",
  "login.title1": "ĐĂNG KÝ",
  "login.title2": "CHÀO MỪNG QUÝ KHÁCH!",
  "registe.title1": "ĐĂNG KÝ",
  "registe.title2": "CHÀO MỪNG QUÝ KHÁCH!",
  "page": {
    "cart": "Xe đẩy",
    "order": "Lịch sử",
    "financing": "Tài trợ",
    "goods": "Các mặt hàng",
    "levelUpgrade": "Nâng cấp",
    "PlatformIntroduction": "Giới thiệu nền tảng",
    "RuleDescription": "Mô tả quy tắc",
    "AgentCooperation": "Hợp tác đại lý",
    "CommonProblem": "Vấn đề thường gặp",
    "MemberUpgrade": "Nâng cấp thành viên",
    "login": "Đăng nhập",
    "forgot": "Quên",
    "registrasi": "Đăng ký",
    "record": "Lịch sử",
    "Recharge": "Nạp tiền",
    "inviteFriends": "Mời bạn bè",
    "Withdraw": "Rút tiền",
    "Service": "Dịch vụ",
    "DialoguePage": "Trang đối thoại",
    "user": "Riêng tư",
    "RansferIn": "Chuyển giao",
    "PersonalCenter": "Trung tâm cá nhân",
    "Portrait": "Chân dung",
    "Name": "Tên",
    "Account": "Tài khoản",
    "Password": "Mật khẩu",
    "PaymentCode": "Mã thanh toán",
    "Site": "Địa điểm",
    "AccountData": "Dữ liệu tài khoản",
    "RechargeRecord": "Bản ghi nạp tiền",
    "WithdrawalRecord": "Hồ sơ rút tiền",
    "InviteFriends": "Mời bạn bè",
    "Announcement": "Sự thông báo",
    "TheTeamReport": "Báo cáo của nhóm",
    "BillRecord": "Bản ghi hóa đơn",
    "BankCardAdd": "Thêm thẻ ngân hàng",
    "NewRecharge": "Nạp tiền",
    "RechargeList": "Danh sách nạp tiền",
    "newWithdraw": "Rút tiền",
    "withdrawList": "danh sách rút tiền",
    "SettingPayPwd": "Đặt Paypass",
    "MakeMoney": "Làm ra tiền",
    "team": "đội",
    "qrPay": "qrcode-pay",
    "bankPay": "thanh toán qua ngân hàng",
    "aboutus": "Về chúng tôi",
    "financial": "Tài chính",
    "financialRecord": "Hồ sơ tài chính",
    "TransferInto": "Chuyển vào",
    "TransferOut": "Rút tiền",
    "CommissionRecord": "Nhiệm vụ",
    "Security": "Bảo vệ",
    "Address": "Địa chỉ nhà",
    "Message": "Thông điệp",
    "InviteTask": "Mời Nhiệm vụ",
    "Notice": "Để ý",
    "modifyPaymentPassword": "Sửa đổi mật khẩu thanh toán",
    "games": "Trò chơi",
    "withdrawRecord": "Rút lại hồ sơ",
    "member": "thành viên",
    "teamReport": "Báo cáo nhóm",
    "agentReport": "Báo cáo đại lý",
    "agentCommission": "Ủy quyền đại diện",
    "verifyPhone": "Xác minh điện thoại",
    "openRecord": "Mở hồ sơ",
    "checkin": "Kiểm tra lịch"
  },
  "copyLink": "Sao chép đường link",
  "teamReport": {
    "title": "Báo cáo nhóm",
    "subTitle": "Tổng quan về nhóm cấp dưới",
    "taskTitle": "Mời {num} người đăng ký và hoàn thành đơn đặt hàng",
    "bonus": "Thưởng",
    "Unfinished": "Chưa xong",
    "Getted": "Được"
  },
  "copy": "Sao chép",
  "address": {
    "name": "Tên",
    "inputPlaceholder": "Vui lòng điền vào",
    "phone": "Điện thoại",
    "areaPlaceholer": "Giao hàng",
    "submit": "Tiết kiệm"
  },
  "password.old": "Mật khẩu cũ",
  "password.old.placeholder": "Vui lòng nhập mật khẩu mới của bạn",
  "password.confirmPassword": "Xác nhận mật khẩu",
  "password.confirmPassword.placeholder": "Vui lòng xác nhận lại mật khẩu",
  "hint.passwordDiff": "Hai mật khẩu không khớp",
  "loginType": {
    "phone": "Điện thoại",
    "email": "E-mail"
  },
  "Login.Daftar.email": "E-mail",
  "Login.emailInput": "Nhập email của bạn",
  "My.PersonalInformation.Password.email": "e-mail",
  "My.PersonalInformation.Password.NewEmail": "Vui lòng nhập email di động của bạn",
  "mine.serviceTools.certify": "Xác minh",
  "Certification": {
    "type": {
      "id": "Số CCCD/CMND",
      "driver": "Bằng lái xe",
      "passport": "Hộ chiếu"
    },
    "name": "Tên",
    "license": "Số giấy phép",
    "upload": "Hình thẻ",
    "submit": "Xác nhận",
    "incompleteInformation": "Vui lòng xem lại thông tin!!",
    "submitSuccess": "Thông tin chứng nhận đã được gửi, vui lòng chờ xem xét!",
    "photo": "Ảnh id",
    "review": "Đang được xem xét",
    "verified": "đã xác minh"
  },
  "upload": {
    "uploading": "Tải lên ...",
    "failed": "tải lên không thành công"
  },
  "login.forgetPassword": "Quên mật khẩu của bạn, xin vui lòng liên hệ với dịch vụ khách hàng!",
  "newAdd.BankNum.placeholder": "Ví dụ: 09xxxxxxxxxx",
  "page.certification": "Nhận dạng",
  "newAdd.page": "trang",
  "newAdd.record": "ghi lại",
  "emailFormatError": "Xin vui lòng",
  "urlParsePlaceholder": "Vui lòng nhập đường link vào",
  "btn.Identify": "Xác nhận nhiệm vụ",
  "produceStatus.confirming": "Tobeconf xác nhận",
  "produceStatus.paying": "Trả tiền",
  "produceStatus.paid": "Trả",
  "produceStatus.done": "Xong",
  "produceStatus.cancle": "Hoàn lại tiền",
  "order.old": "Lệnh thu nhập cao",
  "order.new": "Thường xuyên",
  "welfareDialogTitle": "Lợi ích",
  "home.btn.message": "Thông tin",
  "viGrabDesces": {
    "1": "Mỗi đơn hàng có thể được chọn là đơn hàng may mắn và hoa hồng sẽ được tăng lên {rate}%. Vui lòng liên hệ với người hướng dẫn khuyến mãi của bạn để đăng ký các nhiệm vụ khuyến mãi tiếp theo",
    2: "Giúp người bán trên Shopee hoàn thành việc tạo đơn hàng ảo, tăng khả năng hiển thị và nhận hoa hồng",
    3: "Vui lòng liên hệ với gia sư khuyến mãi của bạn ngay sau khi bạn nhận được đơn hàng, gửi dữ liệu đơn hàng và hoàn thành nhiệm vụ",
    4: "Số lượng nhiệm vụ có hạn, các bạn chú ý để được gia sư tư vấn khuyến mãi khi nhận đơn hàng",
    5: "Số tiền bạn sẽ nhận lại được cấp trong vòng từ 5-10 phút sau khi hoàn thành nhiệm vụ ,nếu có thắc mắc vui lòng tham khảo ý kiến chuyên gia hoặc chăm sóc khách hàng trực tuyến.",
    6: "Hoàn thành các nhiệm vụ Shopee chỉ định để tăng cấp độ VIP và nhận hoa hồng nhiệm vụ Fackbook cao hơn",
    7: "Tích lũy hoàn thành các nhiệm vụ Shopee chỉ định có thể nhận thêm hoa hồng. Để biết phần thưởng cụ thể, hãy tham khảo ý kiến   của chuyên gia khuyến mãi hoặc dịch vụ khách hàng trực tuyến"
  },
  "expectedEarningsTitle": "Nguồn thu nhập được dự kiến",
  rechargeDesc: {
    nav: "Mô tả nạp tiền",
    p1: "bây giờ chúng tôi có rất nhiều nhân viên hoàn thành nhiệm vụ cùng một lúc.",
    p2: "Một người bán không thể cung cấp đủ tài khoản",
    p3: "vì vậy chúng tôi đăng tài khoản người bán của mình vào chỗ trống khác",
    p4: "khi bạn cần hoàn thành nhiệm vụ, bạn có thể liên hệ với bộ phận hỗ trợ bán hàng để đăng ký kênh thanh toán cho bạn"
  },
  chat: {
    list: {
      nav: "Thông điệp",
      hours: "{h} giờ",
      minutes: "{m} phút",
      ago: "{time} trước",
      image: "hình ảnh"
    },
    room: {
      msgPlaceholder: "Nhập tin nhắn...",
      send: "Gửi",
      sender: "Người gửi",
      sendTime: "Gửi thời gian",
      content: 'Nội dung'
    }
  },
  newBank: {
    usdt: {
      name: "Tên",
      currency: "Tiền tệ",
      chainType: "Loại dây chuyền",
      depositAddress: "Địa chỉ gửi tiền",
      submit: "nộp",
    },
    "walletList": {
      pageTitle: 'Quản lý ví',
      "name": "Tên tài khoản:",
      "coinType": "Tên ngân hàng: ",
      "chainType": "Loại dây chuyền:",
      "walletAddress": "Số tài khoản:",
      "addDate": "Thời gian:",
      "btnText": "Thêm địa chỉ ví",
      "deleteConfirmText": "Bạn có chắc chắn muốn xóa địa chỉ ví không?",
      "btnConfirm": "Xóa",
      "btnCancle": "Hủy",
      "successHint": "thành công"
    },
    withdrawNum: "Số lần rút tiền",
    AllOut: "Tất cả",
    balance: "Cân bằng tổng thể",
    handlingFee: "Phí xử lý",
    actualAmount: "Số tiền thực tế"
  },
  "listStatus": {
    "all": "tất cả",
    "progress": "Đang tiến hành",
    "done": "Tất cả",
    "error": "Yêu cầu dữ liệu không thành công",
    "cancel": "Bị hủy bỏ",
    "won": "Đã nhận thưởng"
  },
  "pullRefresh": {
    "pulling": "Kéo xuống để làm mới...",
    "loosing": "Phát hành để làm mới...",
    "loading": "đang tải...",
    "success": "Đang hoàn thành"
  },
  "home": {
    "activeProgress": "Tiến trình sự kiện:",
    "proSubmit": "Tham gia"
  },
  "product": {
    "asc": {
      "price": "Giá",
      "new": "Mới nhất",
      "hot": "Nóng nhất",
      "totalNum": "Tổng nhu cầu",
      "all": "Tất cả sản phẩm"
    },
    "participates": "Số lượng người tham gia: {__}",
    "options": { "all": "Còn lại" },
    "dialog": { "submit": "Tham gia ngay lập tức" },
    "record": {
      "willGet": "Thu nhập trong tương lai: {__}",
      "rate": "Hạ giá: {__}",
      "toPay": "Tiến hành thanh toán",
      "received": "Đã nhận",
      "receivedWarningTitle": "Xác nhận",
      "receivedWarningContent": "Bạn đã nhận được hàng?"
    },
    "step": {
      "bidSuccess": "Đã đấu giá",
      "paySuccess": "Thanh toán thành công",
      "progress": "Chờ đợi",
      "done": "Hoàn thành",
      "returned": "Sự trở lại của Hiệu trưởng và Ủy ban",
      "dropshipping": "Đang chuẩn bị",
      "shipping": "Đang chuyển hàng"
    },
    "details": {
      "nav": "Thông tin chi tiết sản phẩm",
      "price": "Giới hạn số lượng: {__}",
      "issue": "Mã đơn hàng: [{__}]",
      "requiredPeople": "Bắt buộc: {__} Người",
      "remainingPeople": "Còn lại: {__} Người",
      "participating": "Tham gia"
    },
    "status": {
      "progress": "Tiến triển",
      "unstart": "Chưa bắt đầu",
      "done": "Quá hạn"
    },
    "participated": "Đã tham gia",
    "allNeed": "Số đầu",
    "remaining": "Còn lại",
    "buyNum": "Số lượng đã mua:"
  },
  "tabbar.accountData": "Giao dịch",
  "page.products": "Các sản phẩm",
  "tab.activity": "Hoạt động",
  "merchant.all": "tất cả",
  "merchant.long": "thời gian dài",
  "merchant.short": "thời gian ngắn",
  "merchant.hot": "Thương gia nhạc pop",
  "merchant.list": "Danh sách thương nhân",
  "order.status.all": "Tất cả",
  "order.status.waiting": "Chưa giải quyết",
  "order.status.done": "Xong",
  "order.typeTitle": "kiểu",
  "order.totalPrice": "Số tiền đặt cược",
  "order.winPrice": "thu nhập",
  "order.status": "trạng thái",
  "activity.title": "Khu vực hoạt động",
  "activity.time": "thời gian hoạt động",
  "member": {
    "title": {
      "level": "mức độ"
    },
    "label": {
      "indate": "trong ngày",
      "loginData": "Đăng nhập trong thời gian",
      "superior": "thượng đẳng",
      "member": "thành viên"
    },
    "notLogin": "không đăng nhập",
    "toDetails": "Xem chi tiết",
  },
  "my.modifyPass": "Thay đổi mật khẩu",
  "my.wallet": "Cái ví",
  "my.betRecord": "Hồ sơ cá cược",
  "agent": {
    "title": {
      "memberControl": "Quản lý thành viên",
      "share": "Chia sẻ liên kết giới thiệu",
      "agentRead": "Chính sách của cơ quan",
      "betInfo": "Thông tin cá cược",
      "paymentInfo": "Thông tin thanh toán",
      "teamInfo": "Báo cáo nhóm",
      "daily": "Hằng ngày",
      "agent": "Đại lý",
      "agentCommission": "Ủy ban đại lý"
    }
  },
  "btn": {
    "search": "tìm kiếm",
    "sort": {
      "level": "mức độ",
      "indate": "trong ngày"
    },
    "confirm": "Xác nhận",
    "cancel": "Hủy bỏ"
  },
  "recharge.selectCurrency.title": "Chọn loại tiền tệ",
  "withdraw.label.enterAmount": "Số tiền rút",
  "Merchant": "Thương gia",
  "all": "Tất cả",
  "btn.recharge": "Nạp điện",
  "btn.withdraw": "Rút",
  "btn.service": "Dịch vụ",
  "merchant.all": "Tất cả các thương nhân",
  "merchant.hot": "Thương gia nhạc pop",
  "merchant.list": "Danh sách thương nhân",
  "orderDeadlineText": "Hạn chót đặt hàng",
  "orderResultText": "Văn bản kết quả đặt hàng",
  "status.waiting": "Chưa giải quyết",
  "status.done": "Đã được rút ra",
  "status.fail": "lỗi xổ số",
  "status.open": "Khai mạc...",
  "open.big": "Tài",
  "open.small": "Xỉu",
  "open.single": "Chẵn",
  "open.double": "Lẻ",
  "大小单双": "Big Small Single Double",
  "大单": "Đĩa đơn lớn",
  "大双": "Lớn đôi",
  "小单": "Độc thân nhỏ",
  "小双": "đôi nhỏ",
  "btn.clear": "thông thoáng",
  "btn.match": "Xác nhận",
  "hint.moneyLess": "Thiếu cân bằng!",
  "hint.opening": "Đơn đặt hàng không được phép trong quá trình đóng cửa!",
  "hint.matchSuccess": "Trận đấu thành công!",
  "hint.matchFail": "Trận đấu không thành công!",
  "popup.title.buyConfirm": "Xác nhận đơn hàng",
  "popup.text.data": "dữ liệu",
  "yuan": "Yuan",
  "popup.title.matchAmount": "số tiền đối sánh",
  "popup.title.matchContent": "khớp nội dung",
  "hint.confirmBuy": "Vui lòng hoàn thành xác nhận đơn hàng trong thời hạn để tránh bỏ lỡ đơn đặt hàng!",
  "popup.btn.match": "Xác nhận đặt cược",
  "hint.toAddOrder": "Vui lòng chọn Số đặt hàng!",
  "hint.toPerPrice": "Vui lòng nhập số tiền đặt hàng!",
  "popup.title.matchResult": "Kết quả khớp",
  "popup.orderId": "Số đặt hàng",
  "tab.activity": "Hoạt động",
  "toDetails": "Kiểm tra các chi tiết",
  "activity.title": "Khu vực hoạt động",
  "activity.time": "thời gian hoạt động",
  "nav.activityDetails": "Chi tiết hoạt động",
  "order.status.all": "Tất cả",
  "order.status.waiting": "Chưa giải quyết",
  "order.status.done": "Xong",
  "order.typeTitle": "kiểu",
  "order.totalPrice": "Số tiền đặt cược",
  "order.winPrice": "thu nhập",
  "order.status": "trạng thái",
  "nav.orderDetails": "Chi tiết đặt hàng",
  "orderDetails.title.time": "Thời gian cá cược",
  "orderDetails.title.type": "thương gia",
  "orderDetails.title.expect": "Vấn đề",
  "orderDetails.title.method": "phương pháp",
  "orderDetails.title.result": "kết quả",
  "orderDetails.title.inType": "đã mua",
  "orderDetails.title.odds": "tỷ lệ cược",
  "orderDetails.title.inPerPrice": "giá mua",
  "orderDetails.title.inNum": "Số lượng mua",
  "orderDetails.title.winAmount": "thu nhập",
  "game.perPrice.text": "mỗi mục",
  "balance.text": "Sự cân bằng",
  "input.permoney.placeholder": "Nhập số tiền",
  "user.tools.introduce": "giới thiệu",
  "user.tools.crypto": "tiền điện tử",
  "user.tools.ba": "Binance",
  "page.walletList": "Danh sách ví",
  "addWallet": "Thêm vào",
  "walletTypeBank": "Ngân hàng",
  "walletTypeUSDT": "USDT",
  "btn.editWallet": "biên tập",
  "game": {
    "fiveBall": {
      "0": "bóng đầu tiên",
      "1": "bóng thứ hai",
      "2": "bóng thứ ba",
      "3": "bóng thứ tư",
      "4": "bóng thứ năm"
    },
    "broadcast": {
      "title": "Lợi nhuận phát sóng thời gian thực",
      "th_tradeUser": "Người dùng giao dịch",
      "th_tradeType": "Phương pháp giao dịch",
      "th_winAmount": "số tiền lợi nhuận"
    },
    "chooseGameTitle": "Chọn trò chơi",
    "rule": { "title": "Chỉ dẫn", "close": "Đóng" }
  },
  "open.result": 'Kết quả',
  "open.hz": "Tổng",
  "open.openTime": "Thời gian vẽ",
  "game.typeHz": "Tổng",
  "My.PersonalInformation.Password.oldPass": "Vui lòng nhập mật khẩu cũ",
  "hint.registeRead": "Tôi trên 18 tuổi và chấp nhận các điều khoản và điều kiện của trang web này.",
  "login.rememberPassword": "Nhớ mật khẩu",
  "successWithdrawof": "rút thành công",
  "hint.usernameEnter": "Vui lòng nhập tên người dùng!",
  "hint.passwordEnter": "Vui lòng nhập mật khẩu của bạn!",
  "hint.passwordAgainEnter": "Vui lòng xác nhận mật khẩu của bạn!",
  "hint.formidEnter": "Vui lòng nhập mã lời mời!",
  "hint.registeRead": "Tôi đã hơn 18 tuổi và chấp nhận các điều khoản và điều kiện của trang web này.",
  "hint.read": "Hãy đảm bảo đọc thỏa thuận và thử lại!",
  "partner": "Cộng sự",
  "address": {
    "title": "Quản lý địa chỉ",
    "editTitle": "Sửa địa chỉ",
    "default": "Mặc định",
    "addAddress": "Thêm địa chỉ",
    "defaultAddress": "Đặt làm địa chỉ giao hàng mặc định",
    "btn": { "save": "Xác nhận", "delete": "Xóa bỏ" },
    "form": {
      "name": "Tên",
      "placeholderName": "Vui lòng nhập tên của bạn",
      "messageName": "Xin vui lòng điền tên của bạn",
      "phone": "Số điện thoại",
      "placeholderPhone": "Vui lòng nhập số điện thoại",
      "messagePhone": "Vui lòng điền vào số điện thoại di động",
      "address": "Địa chỉ",
      "placeholderAddress": "Vui lòng nhập địa chỉ chi tiết",
      "messageAddress": "Vui lòng điền vào địa chỉ chi tiết"
    }
  },
  "startTime": "Thời gian bắt đầu",
  "endTime": "Thời gian kết thúc",
  "proBuy.label.price": "Giá đặt hàng",
  "proBuy.label.address": "Địa chỉ giao hàng",
  "proBuy.label.placeholder": "Vui lòng chọn một địa chỉ",
  "payPassword": {
    "title": "Thiết lập mật khẩu thanh toán",
    "formList": {
      "oldLabel": "Vui lòng nhập mật khẩu thanh toán cũ của bạn",
      "newLabel": "Vui lòng nhập mật khẩu thanh toán mới"
    }
  },
  "withdraw.dialog.password": "Mật khẩu",
  "withdraw.dialog.cancel": "Hủy bỏ",
  "withdraw.dialog.confirm": "Xác nhận",
  "todayBetAmount": "Số tiền đặt cược hôm nay",
  "todayWinAmount": "Số tiền lãi hôm nay",
  "paypass": "Mật khẩu thanh toán",
  "proRecordDetail": {
    "title": "Chi tiết đặt hàng",
    "order": {
      "title": "Thông tin đặt hàng",
      "no": "Số đặt hàng",
      "number": "Số lượng đơn đặt hàng",
      "price": "Giá đặt hàng",
      "wining": "Xác suất chiến thắng",
      "recipient": "người nhận",
      "orderStatus": "Tình trạng đơn hàng",
      "address": "Địa chỉ giao hàng",
      "notes": "Ghi chú đặt hàng",
      "notwin": "Không trúng",
      "dropshipping": "Đơn trúng đang chuẩn bị",
      "receipt": "Đơn trúng đang phát hàng",
      "received": "Đơn trúng đã nhận hàng"
    }
  },
  "heartEn": {
    "home": { "searchPlaceholder": "Tìm kiếm" },
    "mine": {
      "heart": "Quan tâm",
      "collect": "Theo dõi",
      "historyRecord": "Lịch sử ủng hộ",
      "withdraw": "Rút tiền",
      "modifyPass": "Đổi mật khẩu",
      "history": {
        "vote": "VOTE",
        "voteName": "VOTE tên",
        "ending": "kết quá",
        "endingName": "tên kết quả",
        "votes": "Số điểm vote"
      }
    },
    "goodsDetails": {
      "nav": "Chi tiết",
      "fundraising": "Logo gây quỹ: {__}",
      "content": {
        "money": "Quyên góp/tiền",
        "total": "Tổng số tiền",
        "people": "Số người"
      }
    },
    "cart": {
      "votes": "Số phiếu bầu {__}",
      "deal": "DEAL {__}",
      "selectHeart": "Vui lòng chọn tình huống",
      "tabList": {
        "vote": "Lịch sử bỏ phiếu",
        "result": "Kết quả",
        "period": "Thời gian bỏ phiếu",
        "philanthropists": "Nhà từ thiện",
        "voteNum": "Phiếu bầu"
      },
      "pop": {
        "voteNum": "Số phiếu bầu",
        "voteClick": "Bấm để bỏ phiếu",
        "inputPlaceholder": "Vui lòng nhập số phiếu bầu"
      }
    }
  },
  "Button.send": "Gửi",
  "feedback": {
    "nav": "Nhận xét",
    "hint": "Xin chào, tôi xin lỗi vì chúng tôi tạm thời không thể cung cấp dịch vụ cho bạn, nếu bạn cần giúp đỡ, xin vui lòng để lại tin nhắn, chúng tôi sẽ liên hệ và giải quyết vấn đề của bạn càng sớm càng tốt",
    "uploadLimitHint": "Giới hạn việc tải lên hình ảnh ở JPG, PNG, định dạng GIF và kích thước dưới 5m."
  },
  "label": { "phone": "Điện thoại", "content": "Nội dung" },
  "placeholders": {
    "phone": "Xin vui lòng điền số điện thoại của bạn!",
    "content": "Vui lòng nhập nội dung phản hồi!"
  },
  "required": {
    "phone": "Xin vui lòng điền số điện thoại của bạn!",
    "content": "Vui lòng nhập nội dung phản hồi!"
  },
  "collectList.nav": "Thu thập danh sách",
  "likeList.nav": "Thích danh sách",
  "noMore": "Không còn nữa",
  "recharge.enter": "Đi vào",
  "rechargeAccountEnter": "Vui lòng nhập tên tài khoản ngân hàng của người chuyển nhượng",
  "cardToast": "Vui lòng nhập đầy đủ thông tin thẻ ngân hàng",
  "lottery": {
    "drawsNum": "Số lần rút thăm: {__} lần",
    "newTabs": "Rương kho báu",
    "drawNow": "Bóc thăm ngay",
    "fraction": "Bạn đã hết lượt quay",
    "ruleLeft": "Chúc mừng khách hàng",
    "ruleRight": "trúng thưởng",
    "prize": "Danh sách trúng thưởng",
    "point": "Danh sách đổi điểm thưởng",
    "btn": "xác nhận",
    "tools": {
      "text1": "Luật trung thưởng",
      "btnText": "Chắc chắn",
      "text2": "Dịch vụ trực tuyến",
      "text3": "Lịch sử trúng thưởng"
    },
    "record": {
      "title": "Hồ sơ rút tiền",
      "titles": "kỷ lục chiến thắng",
      "finish": "không còn nữa",
      "loading": "Đang tải..."
    },
    "listText": "Số điểm bắt buộc: {point} điểm"
  },
  "moneyToast": "Vui lòng điền số tiền",
  "hint.inviteBanned": "Rất tiếc, tài khoản của bạn vẫn chưa mở tài khoản gia đình, vui lòng liên hệ bộ phận chăm sóc khách hàng hoặc người cố vấn của bạn để đăng ký mở!",
  "womon": "{__} Người",
  "Dialog.success.title": "Chúc mừng bạn đã chiến thắng !",
  "Dialog.error.title": "Rất tiếc bạn đã không thắng !",
  "Dialog.success.message1": "Phiên cược thắng:",
  "Dialog.success.message2": "Số tiền thắng:",
  "Dialog.error.message1": "Phiên cược thua:",
  "Dialog.error.message2": "Số tiền thua:",
  "login.welcome": "Chào mừng!",
  "mine.credit": "Tín dụng",
  "user.identityVerification": "Đã hoàn tất xác minh danh tính",
  "certified": "Đã chứng nhận",
  "underReview": "Đang xem xét",
  "failed": "Không thành công"
}