/*
 * @Author: your name
 * @Date: 2022-02-23 18:27:08
 * @LastEditTime: 2022-04-08 15:18:40
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \ShopTask-goshop\src\i18n\index.js
 */
import Vue from "vue";
import VueI18n from "vue-i18n";
import Cookies from "js-cookie";
import enLocale from "./en";
import enCom from "@tt/i18n/src/en";
import inLocale from "./idn";
import inCom from "@tt/i18n/src/idn";
import thLocale from "./th";
import thCom from "@tt/i18n/src/th";
import trLocale from "./tr";
import trCom from "@tt/i18n/src/tr";
import vi from "./vi";
import verConfig from "@/utils/verConfig"
// import zhLocale from "./zh";

Vue.use(VueI18n);

export const messages = {
  en: {
    ...enCom,
    ...enLocale,
  },
  // zh: {
  //   ...zhLocale,
  // },
  idn: {
    ...inCom,
    ...inLocale,
  },
  th: {
    ...thCom,
    ...thLocale,
  },
  tr: {
    ...trCom,
    ...trLocale,
  },
  vi,
};

export const Lans = [
  {
    name: "แบบไทย",
    value: "th"
  },
  {
    name: "English",
    value: "en"
  },
  {
    name: "Tiếng Việt",
    value: "vi"
  }
]

export function getLanguage() {
  // 从cookis 里面取语言
  const chooseLanguage = Cookies.get("language");
  if (!chooseLanguage) {
    Cookies.set("language", verConfig.lan || "en");
    localStorage.setItem('language', verConfig.lan || "en")
  }
  return chooseLanguage || verConfig.lan || "en"
}
const i18n = new VueI18n({
  locale: getLanguage(),
  messages,
});

export default i18n;
